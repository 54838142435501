<template>
    <main
        class="pl-4 pt-2 rounded-md h-full dark-mode text-sm"
        :class="processing === true ? 'cursor-wait' : ''"
        style="width: 95vw"
    >
        <back-to @back="back()" />
        <div>
            <span
                v-if="currentDriver !== null"
                :key="refreshDriver"
                class="text-3xl flex items-start mb-5 border-b-2 themed-border"
            >
                <span class="ml-2">
                    {{ currentDriver.FirstName + ' ' + currentDriver.LastName }}
                </span>
            </span>
        </div>
        <span id="activities-block" class="sticky-block bg-zinc-100">
            <span class="bg-white">
                <div
                    class="rounded slider bg-zinc-100 px-4 border-2 h-32 overflow-x-auto overflow-y-hidden darky border-zinc-200"
                >
                    <div class="flex items-center mt-3 justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="displayAll"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('show_disabled_activities') }}</label
                        >
                    </div>
                    <div
                        class="px-2 py-2 whitespace-nowrap inline-flex items-center"
                    >
                        <div
                            v-if="canCreateGlobal === true"
                            class="border-2 success-msg rounded mx-2 border-gray-300 px-2 py-3"
                        >
                            <fa-icon
                                icon="plus"
                                class="fa-xl cursor-pointer"
                                :title="$t('create')"
                                @click="showModal()"
                            ></fa-icon>
                        </div>
                        <div
                            v-for="activity in activities"
                            :key="activity.title"
                            class="px-2 py-2 mx-2 activity-text text-xs shadow rounded"
                            :class="processing ? 'cursor-wait' : 'cursor-grab'"
                            :draggable="canAccess === true"
                            @dragstart="startDrag($event, activity)"
                            :style="`background-color: ${activity.color};`"
                        >
                            <span
                                v-if="canUpdateGlobal === true"
                                class="flex justify-center mb-1"
                            >
                                <button
                                    v-if="
                                        activity.user_id &&
                                        activity.user_id ===
                                            userStore.current.id
                                    "
                                    class="themed-button text-xs text-white transition duration-150 font-semibold px-4 rounded-full"
                                    @click="activityActivation(activity)"
                                >
                                    {{
                                        activity.active === 1
                                            ? $t('deactivate')
                                            : $t('activate')
                                    }}
                                </button>
                                <!-- <fa-icon
                                    icon="trash-can"
                                    :title="$t('delete')"
                                    class="fa-md cursor-pointer mr-2"
                                    @click="deleteActivity(null, activity.id)"
                                ></fa-icon
                                >--><fa-icon
                                    icon="pen"
                                    class="fa-md cursor-pointer ml-1"
                                    :title="$t('edit')"
                                    @click="showModal(null, activity.id)"
                                ></fa-icon>
                            </span>

                            <span
                                class="whitespace-nowrap justify-center flex items-center"
                                :style="`color: ${
                                    activity.type === 'offNotPaid' ? 'red' : ''
                                }`"
                            >
                                <fa-icon
                                    v-if="activity.type === 'offPaid'"
                                    :title="$t('off_paid')"
                                    class="fa-sm mr-1"
                                    icon="user-slash"
                                ></fa-icon>
                                <fa-icon
                                    v-else-if="activity.type === 'offNotPaid'"
                                    :title="$t('off_not_paid')"
                                    class="fa-sm mr-1"
                                    style="color: red"
                                    icon="user-slash"
                                ></fa-icon>

                                <fa-icon
                                    v-else
                                    :title="$t('atwork')"
                                    icon="user"
                                    class="fa-sm mr-1"
                                ></fa-icon>
                                {{ activity.title }}
                            </span>
                            <div class="font-semibold activity-text">
                                {{ `${activity.start} - ${activity.end}` }}
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </span>

        <table
            class="w-full px-4 my-1 table-auto leading-normal"
            :key="refreshPlanning"
        >
            <thead>
                <tr id="table-header" class="darky z-100">
                    <th
                        class="rounded-tl-2xl w-[10%] px-6 py-2 stop-stretching border-b-2 border-r-2 bg-zinc-100 border-zinc-200"
                    >
                        {{ $t('driver') }}
                    </th>
                    <th
                        v-for="(day, i) in weekDays"
                        :key="i"
                        class="px-12 w-[10%] border-zinc-200 py-2 border-b-2 font-semibold tracking-wider whitespace-nowrap"
                        :class="
                            6 === i
                                ? 'rounded-tr-2xl bg-zinc-100 '
                                : 'border-r-2 bg-zinc-100 '
                        "
                    >
                        {{ `${$t(day)} ` }}
                    </th>
                </tr>
            </thead>
            <tbody :key="refreshDriver" v-if="currentDriver !== null">
                <tr class="darky z-100">
                    <td
                        class="px-3 py-10 w-[10%] whitespace-nowrap border-zinc-200 text-xs darky border-separate border-r-2 border-b-2"
                    >
                        <button
                            class="mb-2 text-base cursor-pointer font-semibold"
                            @click="$emit('goToUpdatedriver', currentDriver.id)"
                        >
                            <span class="items-center custom-link">
                                {{
                                    `${currentDriver.FirstName} ${currentDriver.LastName}`
                                }}
                            </span>
                        </button>
                        <div class="mb-1">
                            {{
                                `${$t('contract_hours')} ${
                                    currentDriver.ContractHour
                                } h`
                            }}
                        </div>

                        <div
                            :class="
                                +currentDriver.hours &&
                                +currentDriver.hours.weekTotal &&
                                +currentDriver.ContractHour ===
                                    +currentDriver.hours.weekTotal
                                    ? 'bg-green-500'
                                    : 'bg-red-500'
                            "
                            class="top-0 right-0 bottom-auto left-auto rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-1 text-xs leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full z-10"
                        >
                            {{
                                `${$t('weekly_hours')} ${
                                    currentDriver.hours &&
                                    currentDriver.hours.weekTotal
                                        ? currentDriver.hours.weekTotal
                                        : 0
                                } h `
                            }}
                        </div>
                    </td>
                    <td
                        v-for="(day, dayIndex) in weekDays"
                        :key="dayIndex"
                        class="py-3 px-1 w-[10%] border-zinc-200 text-xs darky border-separate border-r-2 border-b-2"
                        @drop="onDrop($event, `driver-activities`, dayIndex)"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <span
                            :key="refresh"
                            :class="
                                getDriverActivities(dayIndex) &&
                                getDriverActivities(dayIndex).length > 1
                                    ? 'grid grid-cols-2 px-1 gap-1'
                                    : ''
                            "
                        >
                            <div
                                v-for="activity in getDriverActivities(
                                    dayIndex
                                )"
                                :id="`activity-day-${dayIndex}`"
                                :key="activity.Name"
                                class="p-2 activity-text shadow rounded cursor-grab border-2"
                                :class="
                                    getDriverActivities(dayIndex).length > 1
                                        ? 'col-span-1'
                                        : ''
                                "
                                :style="`background-color: ${activity.Color};`"
                                :draggable="canAccess === true"
                                @dragstart="startDrag($event, activity)"
                            >
                                <span
                                    v-if="canAccess === true"
                                    class="flex justify-between mb-1"
                                >
                                    <fa-icon
                                        icon="trash-can"
                                        :title="$t('delete')"
                                        class="fa-md cursor-pointer mr-2"
                                        @click="deleteActivity(activity.Id)"
                                    ></fa-icon>
                                    <fa-icon
                                        icon="pen"
                                        class="fa-md cursor-pointer"
                                        :title="$t('edit')"
                                        @click="showModal(activity)"
                                    ></fa-icon>
                                </span>
                                <div class="font-semibold activity-text">
                                    {{ `${activity.Start} - ${activity.End}` }}
                                </div>
                                <span
                                    class="px-2 flex-wrap justify-center flex items-center"
                                    :style="`color: ${
                                        activity.Type === 'offNotPaid'
                                            ? 'red'
                                            : ''
                                    }`"
                                >
                                    <fa-icon
                                        v-if="activity.Type === 'offPaid'"
                                        :title="$t('off_paid')"
                                        class="fa-sm mr-1"
                                        icon="user-slash"
                                    ></fa-icon>
                                    <fa-icon
                                        v-else-if="
                                            activity.Type === 'offNotPaid'
                                        "
                                        :title="$t('off_not_paid')"
                                        class="fa-sm mr-1"
                                        style="color: red"
                                        icon="user-slash"
                                    ></fa-icon>

                                    <fa-icon
                                        v-else
                                        :title="$t('atwork')"
                                        icon="user"
                                        class="fa-sm mr-1"
                                    ></fa-icon>
                                    {{ activity.Name }}
                                </span>
                            </div>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <modal
            v-if="showEditModal"
            :headerText="modalHeaderText"
            :buttonText="modalButtonText"
            id="addModal"
            :mxWidth="'w-1/4'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <div class="mx-3">
                    <div class="mb-3 items-center flex flex-row mt-2">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                            >{{ $t('title') + ' *' }}</label
                        >
                        <input
                            v-model="activityForm.title"
                            autocomplete="name"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.activityForm.title.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-3 items-center flex flex-row mt-2">
                        <label
                            class="font-semibold text-gray-600 whitespace-nowrap pb-1 mr-6 block"
                            >{{ $t('activity_type') + ' *' }}</label
                        >
                        <Multiselect
                            class="border darky rounded px-3 py-2 mt-1 w-full"
                            v-model="activityForm.type"
                            :options="[
                                { name: $t('atwork'), value: 'atwork' },
                                {
                                    name: $t('off_paid'),
                                    value: 'offPaid',
                                },
                                {
                                    name: $t('off_not_paid'),
                                    value: 'offNotPaid',
                                },
                            ]"
                            :searchable="false"
                            :allow-empty="false"
                            :can-deselect="false"
                            :can-clear="false"
                            label="name"
                            track-by="name"
                        ></Multiselect>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.activityForm.type.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-3 items-center flex flex-row mt-4 px-12">
                        <label
                            class="font-semibold text-gray-600 pb-1 mr-2 block"
                            >{{ $t('pick_color') }}</label
                        >

                        <input
                            type="color"
                            v-model="activityForm.color"
                            id="favcolor"
                            name="favcolor"
                            class="cursor-pointer"
                        />
                        <!-- class="border rounded px-3 py-2 mt-1 w-full" -->
                    </div>
                    <div class="grid grid-cols-2 gap-2 mt-4 px-3">
                        <div class="col-span-1">
                            <label
                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('start_time') + ' *' }}</label
                            >
                            <input
                                type="time"
                                v-model="activityForm.start"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.activityForm.start.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1">
                            <label
                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('end_time') + ' *' }}</label
                            >
                            <input
                                type="time"
                                v-model="activityForm.end"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.activityForm.end.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-xs italic mt-1 mb-2" v-if="badTimeRange">
                        <div class="error-msg">
                            {{ $t('bad_time_range') }}
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </div>
            </template>
        </modal>
        <modal
            v-if="openOverlapModal"
            :headerText="'overlap_modal_header'"
            :buttonText="'overlap_modal_button'"
            id="overlapModal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="forcedByUser(false)"
            @sendFromModal="forcedByUser(true)"
        >
            <template #body>
                <div class="mx-3">
                    <div class="flex justify-center mt-2">
                        {{ $t('overlap_activity') }}
                    </div>
                    <div class="flex justify-center">
                        {{ $t('overlap_activity2') }}
                    </div>
                </div></template
            ></modal
        >
        <modal
            v-if="openIsUsedModal"
            :headerText="'is_used_modal_header'"
            :buttonText="'is_used_modal_button_update_all'"
            id="isUsedModal"
            :mxWidth="'max-w-3xl'"
            :buttonAlign="'justify-center'"
            :secondSubmit="true"
            :secondSubmitText="$t('create_new_activity')"
            @closeModal="forcedByUser(false, true)"
            @sendFromModal="forcedByUser(true, true, $event)"
        >
            <template #body>
                <div class="mx-4 text-base">
                    <div class="flex justify-center mt-3">
                        {{ $t('is_used_activity') }}
                    </div>
                    <div class="flex mt-2 justify-center">
                        {{ $t('is_used_activity2') }}
                    </div>
                    <div class="flex mt-2 justify-center">
                        {{ $t('is_used_activity3') }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import BackTo from '@/components/elements/BackTo.vue'
import Modal from '@/components/atoms/Modal.vue'
import { useUserStore } from '@/stores/userStore'
import { useActivityStore } from '@/stores/activityStore'
import { useDriverStore } from '@/stores/driverStore'
import slider from '@/mixins/slider'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
export default {
    name: 'DriverPlanning',
    components: { BackTo, Multiselect, Modal },
    mixins: [slider],
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.activityStore.search({
            query: [],
            filter: [{ dbField: 'Active', value: 1 }],
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        })
        this.setSlider()
    },
    data() {
        return {
            forceActivity: null,
            openOverlapModal: false,
            forceActivityUpdate: null,
            duplicate: false,
            openIsUsedModal: false,
            refresh: 0,
            displayAll: false,
            badTimeRange: false,
            updateGlobal: false,
            newGlobalActivity: false,
            createActivity: false,
            modalHeaderText: 'edit_activity',
            modalButtonText: 'update',
            showEditModal: false,
            activityForm: null,
            drag: false,
            currentPlanningInterval: null,
            refreshPlanning: 0,
            refreshDriver: 0,
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            processing: false,
            weekDays: [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
        }
    },
    validations() {
        return {
            activityForm: {
                title: { required },
                type: { required },
                start: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                end: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
            },
        }
    },
    watch: {
        currentDriver() {
            this.refreshDriver += 1
        },
        displayAll(newVal) {
            let filter = []
            if (newVal === false) {
                filter = [{ dbField: 'Active', value: 1 }]
            }
            this.activityStore.search({
                query: [],
                filter,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
            })
        },
        driverActivities() {
            this.refreshPlanning += 1
        },
    },
    computed: {
        ...mapWritableState(useDriverStore, {
            currentDriver: 'current',
            driverActivities: 'currentPlanning',
        }),
        ...mapState(useActivityStore, {
            activities: 'all',
            currentGlobal: 'current',
        }),
        ...mapStores(useActivityStore, useUserStore),
        canAccess() {
            return checkAccess(
                this.userStore,
                actionsAccess.driverPlanning.update
            )
        },
        canCreateGlobal() {
            return checkAccess(
                this.userStore,
                actionsAccess.weeklyPlanning.create
            )
        },
        canUpdateGlobal() {
            return checkAccess(
                this.userStore,
                actionsAccess.weeklyPlanning.updateActivity
            )
        },
    },

    methods: {
        ...mapActions(useDriverStore, [
            'addPlanningBaseActivity',
            'fetchDriverPlanning',
            'deleteBasePlanning',
            'updateBasePlanning',
        ]),
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.currentDriver = null
            this.driverActivities = null

            this.$emit('back', 'drivers')
        },
        getDriverActivities(day) {
            if (this.driverActivities !== null) {
                const activities = this.driverActivities.filter(
                    (activity) => +activity.WeekDay === day
                )
                return activities // [...activities] ??
            }
            return []
        },
        startDrag(evt, activity) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'

            evt.dataTransfer.setData('draggedItemId', activity.id)
            evt.dataTransfer.setData('draggedItemList', activity.list)
        },
        onDrop(evt, list, dayIndex) {
            this.forceActivity = null
            this.processing = true
            const draggedItemId = evt.dataTransfer.getData('draggedItemId')
            const draggedItemList = evt.dataTransfer.getData('draggedItemList')
            let currentList = this.activities
            if (draggedItemList !== 'activities') {
                currentList = this.driverActivities
            }
            const activity = currentList.find(
                (act) => act.id === +draggedItemId
            )
            let timeOverlap = false
            timeOverlap = this.checkTimeOverlap(activity, dayIndex, evt)
            if (timeOverlap === false) {
                this.addPlanningBaseActivity({
                    Name: activity.title,
                    Color: activity.color,
                    IdUser: this.currentDriver.IdUser,
                    End: activity.end,
                    IdPlanningActivity: activity.base_activity_id,
                    Type: activity.type,
                    Start: activity.start,
                    WeekDay: dayIndex,
                    CreationUserId: this.userStore.current.id,
                    ModificationUserId: this.userStore.current.id,
                })
                    .then(() => {
                        this.fetchDriverPlanning({
                            idUser: this.currentDriver.IdUser,
                        }).then(() => {
                            this.processing = false
                            this.refreshPlanning += 1
                            this.forceActivity = null
                        })
                    })
                    .catch(() => {
                        this.processing = false
                        this.forceActivity = null

                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                const overlapInterval = setInterval(() => {
                    if (this.forceActivity !== null) {
                        clearInterval(overlapInterval)

                        if (this.forceActivity === true) {
                            this.addPlanningBaseActivity({
                                Name: activity.title,
                                Color: activity.color,
                                IdUser: this.currentDriver.IdUser,
                                End: activity.end,
                                IdPlanningActivity: activity.base_activity_id,
                                Type: activity.type,
                                Start: activity.start,
                                WeekDay: dayIndex,
                                CreationUserId: this.userStore.current.id,
                                ModificationUserId: this.userStore.current.id,
                            })
                                .then(() => {
                                    this.fetchDriverPlanning({
                                        idUser: this.currentDriver.IdUser,
                                    }).then(() => {
                                        this.processing = false
                                        this.refreshPlanning += 1
                                        this.forceActivity = null
                                    })
                                })
                                .catch(() => {
                                    this.processing = false
                                    this.forceActivity = null
                                    this.$toast.error(this.$t('error_occured'))
                                })
                        } else {
                            this.processing = false
                            this.forceActivity = null
                            this.$toast.info(this.$t('action_canceled'))
                        }
                    }
                }, 150)
            }
        },
        deleteActivity(activityId = null) {
            this.processing = true
            this.deleteBasePlanning(activityId)
                .then(() => {
                    this.$toast.success(this.$t('deleted_ok'))
                    this.fetchDriverPlanning({
                        idUser: this.currentDriver.IdUser,
                    }).then(() => {
                        this.processing = false
                        this.refreshPlanning += 1
                    })
                })
                .catch(() => {
                    this.processing = false
                    this.$toast.error(this.$t('deleted_ko'))
                })
        },
        activityActivation(activity) {
            if (activity.active === 1) {
                this.activityStore.deactivate(activity.id).then(() => {
                    let filter = []
                    if (this.displayAll === false) {
                        filter = [{ dbField: 'Active', value: 1 }]
                    }
                    this.activityStore.search({
                        query: [],
                        filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    })
                })
            } else {
                const form = {
                    // CreationUserId: activity.creation_user_id,
                    // CreationDate: activity.creation_date,
                    ModificationUserId: this.userStore.current.id,
                    Color: activity.color,
                    End: activity.end,
                    Start: activity.start,
                    Name: activity.title,
                    Active: 1,
                    Type: activity.type,
                    IdUser: activity.user_id,
                }
                this.activityStore
                    .update({
                        id: activity.id,
                        form,
                    })
                    .then((res) => {
                        let filter = []
                        if (this.displayAll === false) {
                            filter = [{ dbField: 'Active', value: 1 }]
                        }
                        this.activityStore.search({
                            query: [],
                            filter,
                            sortDirection: this.sortDirection,
                            sortField: this.sortField,
                            perPage: this.perPage,
                            page: this.page,
                        })
                        this.fetchDriverPlanning({
                            idUser: this.currentDriver.IdUser,
                        })
                    })
                    .catch((err) => {
                        this.$toast.error('activity_create_ko')
                    })
            }
        },
        showModal(driverBaseActivity = null, activityId = null) {
            if (driverBaseActivity !== null) {
                // console.log(driverBaseActivity)
                this.activityForm = {
                    id: driverBaseActivity.Id,
                    title: driverBaseActivity.Name,
                    color: driverBaseActivity.Color,
                    IdUser: driverBaseActivity.IdUser,
                    end: driverBaseActivity.End,
                    IdPlanningActivity: driverBaseActivity.IdPlanningActivity,
                    start: driverBaseActivity.Start,
                    WeekDay: driverBaseActivity.WeekDay,
                    CreationUserId: driverBaseActivity.CreationUserId,
                    type: driverBaseActivity.Type,
                    list: `driver-${driverBaseActivity.IdUser}-activities`,
                }
            } else if (activityId === null) {
                this.modalHeaderText = 'new_activity'
                this.modalButtonText = 'save'

                this.createActivity = true

                this.newGlobalActivity = true
                this.activityForm = {
                    // id: this.activities.length + 1,
                    title: '',
                    color: '#fff8dc',
                    start: '08:00',
                    end: '16:00',
                    list: 'activities',
                    type: 'atwork',
                    active: 1,
                    userId: this.userStore.current.id,
                }
            } else {
                this.updateGlobal = true
                this.activityForm = this.activities.find(
                    (act) => act.id === +activityId
                )
                this.activityForm.userId = this.userStore.current.id
                this.activityIndexInUpdate = this.activities.indexOf(
                    this.activityForm
                )
            }
            this.showEditModal = true
        },

        async submitModal() {
            this.processing = true

            this.badTimeRange = false
            this.v$.activityForm.$reset()

            const isFormCorrect = await this.v$.$validate()
            const hasTimeErrors = this.checkTimes()
            if (isFormCorrect === true && hasTimeErrors === false) {
                if (
                    this.createActivity === false &&
                    this.updateGlobal === true
                ) {
                    // this.activities[this.activityIndexInUpdate] =
                    //     this.activityForm
                    this.activityStore
                        .fetchOne(this.activityForm.id)
                        .then(() => {
                            if (this.currentGlobal.IsUsed === 1) {
                                this.openIsUsedModal = true
                                this.processing = false
                                this.forceActivityUpdate = null

                                const isUsedInterval = setInterval(() => {
                                    if (this.forceActivityUpdate !== null) {
                                        clearInterval(isUsedInterval)

                                        if (this.duplicate === true) {
                                            this.processing = true

                                            const form = {
                                                CreationUserId:
                                                    this.userStore.current.id,
                                                ModificationUserId:
                                                    this.userStore.current.id,
                                                Color: this.activityForm.color,
                                                End: this.activityForm.end,
                                                Start: this.activityForm.start,
                                                Name: this.activityForm.title,
                                                Active: 1,
                                                Type: this.activityForm.type,
                                                IdUser: this.userStore.current
                                                    .id,
                                            }

                                            this.closeModal()
                                            let filter = []
                                            if (this.displayAll === false) {
                                                filter = [
                                                    {
                                                        dbField: 'Active',
                                                        value: 1,
                                                    },
                                                ]
                                            }
                                            this.activityStore
                                                .create(form)
                                                .then((res) => {
                                                    this.activityStore
                                                        .search({
                                                            query: [],
                                                            filter,
                                                            sortDirection:
                                                                this
                                                                    .sortDirection,
                                                            sortField:
                                                                this.sortField,
                                                            perPage:
                                                                this.perPage,
                                                            page: this.page,
                                                        })
                                                        .then(() => {
                                                            this.processing = false
                                                            this.duplicate = false
                                                            this.refresh += 1
                                                        })
                                                })
                                                .catch((err) => {
                                                    this.processing = false
                                                    this.duplicate = false

                                                    this.$toast.error(
                                                        'activity_create_ko'
                                                    )
                                                })
                                        } else if (
                                            this.forceActivityUpdate === true
                                        ) {
                                            this.processing = true

                                            const form = {
                                                ModificationUserId:
                                                    this.userStore.current.id,
                                                Color: this.activityForm.color,
                                                End: this.activityForm.end,
                                                Start: this.activityForm.start,
                                                Name: this.activityForm.title,
                                                Active: this.activityForm
                                                    .active,
                                                Type: this.activityForm.type,
                                                IdUser: this.userStore.current
                                                    .id,
                                                Id: this.activityForm.id,
                                            }
                                            this.closeModal()
                                            let filter = []
                                            if (this.displayAll === false) {
                                                filter = [
                                                    {
                                                        dbField: 'Active',
                                                        value: 1,
                                                    },
                                                ]
                                            }
                                            this.activityStore
                                                .update({
                                                    id: form.Id,
                                                    form,
                                                })
                                                .then((res) => {
                                                    this.fetchDriverPlanning({
                                                        idUser: this
                                                            .currentDriver
                                                            .IdUser,
                                                    })
                                                    this.activityStore
                                                        .search({
                                                            query: [],
                                                            filter,
                                                            sortDirection:
                                                                this
                                                                    .sortDirection,
                                                            sortField:
                                                                this.sortField,
                                                            perPage:
                                                                this.perPage,
                                                            page: this.page,
                                                        })
                                                        .then(() => {
                                                            this.processing = false

                                                            this.refresh += 1
                                                        })
                                                })
                                                .catch((err) => {
                                                    this.processing = false

                                                    this.$toast.error(
                                                        'update_ko'
                                                    )
                                                })
                                        } else {
                                            this.processing = false
                                            this.duplicate = false
                                            this.forceActivity = null
                                            this.$toast.info(
                                                this.$t('action_canceled')
                                            )
                                        }
                                    }
                                }, 150)
                            } else {
                                this.processing = true

                                const form = {
                                    ModificationUserId:
                                        this.userStore.current.id,
                                    Color: this.activityForm.color,
                                    End: this.activityForm.end,
                                    Start: this.activityForm.start,
                                    Name: this.activityForm.title,
                                    Active: this.activityForm.active,
                                    Type: this.activityForm.type,
                                    IdUser: this.userStore.current.id,
                                    Id: this.activityForm.id,
                                }
                                this.closeModal()
                                let filter = []
                                if (this.displayAll === false) {
                                    filter = [
                                        {
                                            dbField: 'Active',
                                            value: 1,
                                        },
                                    ]
                                }
                                this.activityStore
                                    .update({
                                        id: form.Id,
                                        form,
                                    })
                                    .then((res) => {
                                        this.activityStore
                                            .search({
                                                query: [],
                                                filter,
                                                sortDirection:
                                                    this.sortDirection,
                                                sortField: this.sortField,
                                                perPage: this.perPage,
                                                page: this.page,
                                            })
                                            .then(() => {
                                                this.getMonday(this.today)

                                                const begin = this.week[0]
                                                    .toISOString()
                                                    .slice(0, 10)
                                                const end = this.week[6]
                                                    .toISOString()
                                                    .slice(0, 10)

                                                const payload = {
                                                    begin: begin.replaceAll(
                                                        '-',
                                                        ''
                                                    ),
                                                    end: end.replaceAll(
                                                        '-',
                                                        ''
                                                    ),
                                                }
                                                this.fetchDriversRealPlannings(
                                                    payload
                                                ).then(() => {
                                                    this.processing = false
                                                })
                                                this.refresh += 1
                                            })
                                    })
                                    .catch((err) => {
                                        this.$toast.error('update_ko')
                                    })
                            }
                        })
                } else if (this.newGlobalActivity === true) {
                    const form = {
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                        Color: this.activityForm.color,
                        End: this.activityForm.end,
                        Start: this.activityForm.start,
                        Name: this.activityForm.title,
                        Active: 1,
                        Type: this.activityForm.type,
                        IdUser: this.userStore.current.id,
                    }
                    this.activityStore
                        .create(form)
                        .then((res) => {
                            this.processing = false
                            let filter = []
                            if (this.displayAll === false) {
                                filter = [{ dbField: 'Active', value: 1 }]
                            }
                            this.activityStore.search({
                                query: [],
                                filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                            })
                        })
                        .catch((err) => {
                            this.processing = false

                            this.$toast.error('activity_create_ko')
                        })
                        .finally(() => {
                            this.refresh += 1

                            this.closeModal()
                        })
                } else {
                    const form = {
                        Id: this.activityForm.id,
                        Name: this.activityForm.title,
                        Color: this.activityForm.color,
                        IdUser: this.activityForm.IdUser,
                        End: this.activityForm.end,
                        IdPlanningActivity:
                            this.activityForm.IdPlanningActivity,
                        Start: this.activityForm.start,
                        WeekDay: this.activityForm.WeekDay,
                        CreationUserId: this.activityForm.CreationUserId,
                        Type: this.activityForm.type,
                        ModificationUserId: this.userStore.current.id,
                    }
                    this.updateBasePlanning({
                        id: this.activityForm.id,
                        form,
                    })
                        .then(() => {
                            this.$toast.success(this.$t('update_ok'))
                            this.fetchDriverPlanning({
                                idUser: this.currentDriver.IdUser,
                            })
                                .then(() => {
                                    this.processing = false
                                    this.refreshPlanning += 1
                                })
                                .catch(() => {
                                    this.processing = false
                                    this.$toast.error(this.$t('error_occured'))
                                })
                        })
                        .catch(() => {
                            this.processing = false
                            this.$toast.error(this.$t('update_ko'))
                        })
                    this.refresh += 1

                    this.closeModal()
                }
            }
        },

        forcedByUser(isForced, isUsedModal = false, secondSubmit = false) {
            this.processing = isForced
            if (isUsedModal === true) {
                this.forceActivityUpdate =
                    isForced === true && secondSubmit === false

                this.duplicate = secondSubmit
                this.openIsUsedModal = false
                if (isForced === false && secondSubmit === false) {
                    this.closeModal()
                }
            } else {
                this.forceActivity = isForced

                this.openOverlapModal = false
            }
        },
        checkTimeOverlap(activityToAdd, dayIndex, evt) {
            let overlap = false
            // we create a new date with the time we already have (date itself doesnt matter it jsut needds to be the same for both compared times)
            // after that we can compare these two dates with getTime( date js function)
            this.getDriverActivities(dayIndex).forEach((driverAct) => {
                const [driverActStartHour, driverActStartMinutes] =
                    driverAct.Start.split(':')
                const [driverActEndHour, driverActEndMinutes] =
                    driverAct.End.split(':')
                const [activityToAddStartHour, activityToAddStartMinutes] =
                    activityToAdd.start.split(':')
                const [activityToAddEndHour, activityToAddEndMinutes] =
                    activityToAdd.end.split(':')

                const drActivityStart = new Date(
                    2022,
                    0,
                    1,
                    +driverActStartHour,
                    +driverActStartMinutes
                )
                const drActivityEnd = new Date(
                    2022,
                    0,
                    1,
                    +driverActEndHour,
                    +driverActEndMinutes
                )
                const actToAddStart = new Date(
                    2022,
                    0,
                    1,
                    +activityToAddStartHour,
                    +activityToAddStartMinutes
                )
                const actToAddEnd = new Date(
                    2022,
                    0,
                    1,
                    +activityToAddEndHour,
                    +activityToAddEndMinutes
                )

                if (
                    drActivityStart.getTime() === actToAddStart.getTime() ||
                    drActivityStart.getTime() <
                        actToAddStart.getTime() <
                        drActivityEnd.getTime() ||
                    drActivityStart.getTime() <
                        actToAddEnd.getTime() <
                        drActivityEnd.getTime()
                ) {
                    overlap = true
                    this.processing = false
                    this.openOverlapModal = true
                }
            }) // activités existantes du user

            return overlap
        },
        checkTimes() {
            if (
                this.activityForm.end < this.activityForm.start ||
                this.activityForm.end === this.activityForm.start
            ) {
                this.badTimeRange = true
                return true
            }
            return false
        },
        closeModal() {
            this.badTimeRange = false
            this.v$.activityForm.$reset()
            this.showEditModal = false
            this.activityForm = null
            this.driverIndexInUpdate = null
            this.activityIndexInUpdate = null
            this.createActivity = false
            this.newGlobalActivity = false
            this.updateGlobal = false
            this.processing = false
        },
    },
    unmounted() {
        this.currentDriver = null
    },
}
</script>
<style lang="scss" scoped>
.moving-card {
    @apply opacity-50 bg-gray-100 border border-blue-500;
}
.sticky-block {
    position: sticky;
    z-index: 12;
    width: 100vw;
    top: 7rem; /* Don't forget this, required for the stickiness */
}
thead {
    position: sticky;
    z-index: 10;
    top: 17rem; /* Don't forget this, required for the stickiness */
}
</style>
