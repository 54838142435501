import { defineStore } from 'pinia'

import { useUserStore } from './userStore'

import { useContactClientHistoryStore } from './contactClientHistoryStore'
import { useContractStore } from './contractStore'

const useClientStore = defineStore('client', {
    state: () => ({
        all: null,
        count: null,
        addresses: null,
        addressesCount: null,
        fetchError: false,
        totalPages: 1,
        current: null,
        currentDeliveryAddresses: null,
        currentDeliveryCount: null,
        currentContactsCount: null,
        currentContacts: null,
        currentContact: null,
        currentAddress: null,
        businessHours: null,
        loading: false,
        currentClientContactIds: null,
        currentClientPickup: null, // for B2C
        currentClientPickupAddresses: null,
        containers: null,
        currentContainer: null,
        containersCount: null,
        containersTotalPages: 1,
    }),
    actions: {
        search(payload) {
            this.fetchError = false

            this.loading = true
            // this.current = null
            this.currentContacts = null
            this.currentClientContactIds = null

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const clients = []
                            // TODO récupérer les contacts
                            res.data.Records.forEach((client) => {
                                const formattedClient = {
                                    company_name: client.Name,
                                    id: +client.Id,
                                    address: `${client.Address1} ${
                                        client.Address2 !== 'null' &&
                                        client.Address2 !== '' &&
                                        client.Address2 !== null
                                            ? client.Address2
                                            : ''
                                    } ${
                                        client.Address3 !== 'null' &&
                                        client.Address3 !== '' &&
                                        client.Address3 !== null
                                            ? client.Address3
                                            : ''
                                    }`,
                                    postal_code: client.PostalCode,
                                    city: client.City,
                                    country: client.Country,
                                    registration_number: +client.Siret,
                                    client_type: client.ClientType,
                                    payment_type: client.PaymentType,
                                    universe: client.Universe
                                        ? client.Universe.Name
                                        : '',
                                    type: client.Type,
                                    creation_date: client.CreationDate,
                                    email: client.Email,
                                    vat_intra: client.VatIntra,
                                    rcs: client.RCS,
                                    // contact_firstname: client.FirstName,
                                    // contact_lastname: client.LastName,
                                    // contact_email: client.Email,
                                    // phone: client.Phone,
                                    // id_external: client.IdExternal,
                                    id_user_facturation:
                                        +client.IdUserFacturation,
                                    id_address_facturation:
                                        +client.IdAddressFacturation,
                                    voucher_price: +client.VoucherPrice,
                                    voucher_limit: +client.VoucherLimit,
                                    informations: client.Informations,
                                    creation_user_id: +client.CreationUserId,
                                    defaultContainer: client.DefaultContainer,
                                    idDefaultContainer:
                                        +client.IdDefaultContainer,
                                }
                                clients.push(formattedClient)
                            })
                            this.all = clients
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchContainers(payload) {
            const userStore = useUserStore()
            this.fetchError = false

            this.loading = true

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'IdClient') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/container/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/container/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const containers = []
                            // TODO récupérer les contacts
                            res.data.Records.forEach((container) => {
                                let client = null
                                if (payload.addClientInfo) {
                                    client = this.all.find(
                                        (cl) => cl.id === +container.IdClient
                                    )
                                }
                                const formattedContainer = {
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet' ||
                                        container.Name === 'envelope'
                                            ? this.i18n.global.t(container.Name)
                                            : container.Name,
                                    id: +container.Id,
                                    active: +container.Active,
                                    owned_by:
                                        +container.IdClient === 0 &&
                                        userStore.isB2C === false
                                            ? this.i18n.global.t(
                                                  'global_pack_format'
                                              )
                                            : this.i18n.global.t(
                                                  'belongs_to_other_user'
                                              ),
                                    size: `${container.Length}cm x ${container.Width}cm x ${container.Height} cm`,
                                    client: +container.IdClient,
                                    creation_date: container.CreationDate,
                                    description: container.Description,
                                    creation_user_id: +container.CreationUserId,
                                    subInfoForListDisplay: client
                                        ? client.company_name
                                        : '',
                                    subInfoText: client ? 'client' : '',
                                    multiParamItemAction: true,
                                }
                                containers.push(formattedContainer)
                            })
                            this.containers = containers
                            this.containersCount = +res.data.TotalResults
                            this.containersTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.containers = null
                            this.containersCount = 0
                            this.containersTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        async fetchAddresses(payload) {
            // commit('setCurrentAddress', null)
            this.loading = true
            this.fetchError = false

            const clients = await JSON.parse(JSON.stringify(this.all))
            const clientIds = []
            let query = []
            if (payload.clientId) {
                clientIds.push(payload.clientId)
                if (payload.origin === null) {
                    this.fetchOne(payload.clientId)
                }
            } else {
                clients.forEach((client) => {
                    clientIds.push(client.id)
                })
            }
            query.push(
                `{"IdClient":[{"operator":"IN","value":[${clientIds}]}]}`
            )
            if (payload.origin && payload.origin === false) {
                query.push(
                    `{"AddressType":[{"operator":"LIKE","value":"destination"}]}`
                )
            }
            if (payload.query && payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter && payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    query.push(
                        `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                    )
                })
            }
            const sortDirection = payload.sortDirection || 'ASC'
            const sortField = payload.sortField || 'CreationDate'
            const perPage = payload.perPage || 10
            const page = payload.page || 1

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/clientAddress/search?nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            } else {
                apiUrl = `/api/v1/clientAddress/search?query={"Query":[${query}]}&nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const addresses = []
                            let count = +res.data.TotalResults
                            const totalPages = +res.data.TotalPages

                            res.data.Records.forEach((address) => {
                                const client = clients
                                    ? JSON.parse(JSON.stringify(clients)).find(
                                          (el) => el.id === +address.IdClient
                                      )
                                    : this.current
                                if (payload.origin && payload.origin !== null) {
                                    res.data.Records.forEach(
                                        (address, index) => {
                                            if (
                                                res.data.Records[index]
                                                    .AddressType === 'origin'
                                            ) {
                                                res.data.Records.splice(
                                                    index,
                                                    1
                                                )
                                                count -= 1
                                            }
                                        }
                                    )
                                }
                                if (client) {
                                    const formattedAddress = {
                                        id: +address.Id,
                                        client: client.company_name,
                                        name: address.Name,
                                        address: `${address.Address1} ${
                                            address.Address2 !== 'null' &&
                                            address.Address2 !== '' &&
                                            address.Address2 !== null
                                                ? address.Address2
                                                : ''
                                        } ${
                                            address.Address3 !== 'null' &&
                                            address.Address3 !== '' &&
                                            address.Address3 !== null
                                                ? address.Address3
                                                : ''
                                        }`,
                                        postal_code: address.PostalCode,
                                        city: address.City,
                                        country: address.Country,

                                        type: address.AddressType,
                                        lat: parseFloat(address.Latitude),
                                        lng: parseFloat(address.Longitude),
                                        additional_info:
                                            address.AdditionalInformation,
                                        creation_date: address.CreationDate,
                                        hours: address.Hours,
                                    }
                                    addresses.push(formattedAddress)
                                }
                            })

                            this.addresses = addresses
                            this.addressesCount = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.addresses = null
                            this.addressesCount = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })

                    .catch((err) => {
                        this.loading = false
                        this.addresses = null
                        this.addressesCount = 0
                        this.totalPages = 0
                        reject(err)
                    })
            })
        },
        async fetchPickUpAddresses(payload) {
            // commit('setCurrentAddress', null)
            this.loading = true
            this.fetchError = false

            const clients = await JSON.parse(JSON.stringify(this.all))
            const clientIds = []
            let query = []
            clientIds.push(payload.clientId)
            // dispatch('fetchOne', payload.clientId)

            query.push(
                `{"IdClient":[{"operator":"IN","value":[${clientIds}]}]}`
            )

            query.push(`{"AddressType":[{"operator":"LIKE","value":"origin"}]}`)

            if (payload.query && payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter && payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    query.push(
                        `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                    )
                })
            }
            const sortDirection = payload.sortDirection || 'ASC'
            const sortField = payload.sortField || 'CreationDate'
            const perPage = payload.perPage || 10
            const page = payload.page || 1

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/clientAddress/search?nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            } else {
                apiUrl = `/api/v1/clientAddress/search?query={"Query":[${query}]}&nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const addresses = []
                            const count = +res.data.TotalResults
                            const totalPages = +res.data.TotalPages

                            res.data.Records.forEach((address) => {
                                const client = clients
                                    ? JSON.parse(JSON.stringify(clients)).find(
                                          (el) => el.id === +address.IdClient
                                      )
                                    : this.current

                                if (client) {
                                    const formattedAddress = {
                                        id: +address.Id,
                                        client: client.company_name,
                                        name: address.Name,
                                        address: `${address.Address1} ${
                                            address.Address2 !== 'null' &&
                                            address.Address2 !== '' &&
                                            address.Address2 !== null
                                                ? address.Address2
                                                : ''
                                        } ${
                                            address.Address3 !== 'null' &&
                                            address.Address3 !== '' &&
                                            address.Address3 !== null
                                                ? address.Address3
                                                : ''
                                        }`,
                                        contact_phone: address.ContactPhone,
                                        contact_name: address.ContactName,
                                        contact_email: address.ContactEmail,
                                        postal_code: address.PostalCode,
                                        city: address.City,
                                        country: address.Country,

                                        lat: parseFloat(address.Latitude),
                                        lng: parseFloat(address.Longitude),
                                        driver_info: address.DriverInformation,
                                        additional_info:
                                            address.AdditionalInformation,
                                        creation_date: address.CreationDate,
                                        hours: address.Hours,
                                    }
                                    addresses.push(formattedAddress)
                                }
                            })

                            this.currentClientPickupAddresses = addresses
                        } else {
                            this.currentClientPickupAddresses = null
                        }
                        resolve(res)
                    })

                    .catch((err) => {
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/client', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        createContact(payload) {},
        createDeliveryAddress(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/clientAddress', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateDeliveryAddress(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/clientAddress/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (payload.form.openingHours) {
                            // TODO refaire un tableau avec cex qui sont renseignés
                            this.addOpeningHours(payload.form.openingHours)
                        }
                        this.currentAddress = null
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateExtraInfosDeliveryAddress(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(
                        `/api/v1/clientAddress/${payload.id}`,
                        payload.form,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (payload.form.openingHours) {
                            // TODO refaire un tableau avec cex qui sont renseignés
                            this.addOpeningHours(payload.form.openingHours)
                        }
                        this.currentAddress = null
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        addOpeningHours(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/clientAddressHour`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateOpeningHours(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/clientAddressHour/${payload.id}`,
                        payload.hour,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteOpeningHours(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/clientAddressHour/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // console.log(res)
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchBusinessHours(payload) {
            // https://{{APIURL}}/v1/clientaddress/1/hours
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/clientAddress/${payload}/hours`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const hours = []
                        res.data.Records.forEach((hour) => {
                            const formattedHour = {
                                creation_date: hour.CreationDate,
                                creation_user_id: +hour.CreationUserId,
                                day: +hour.Day,
                                hourBegin: hour.HourBegin
                                    ? hour.HourBegin.substring(
                                          0,
                                          hour.HourBegin.length - 3
                                      )
                                    : null,
                                hourEnd: hour.HourEnd
                                    ? hour.HourEnd.substring(
                                          0,
                                          hour.HourEnd.length - 3
                                      )
                                    : null,
                                id: +hour.Id,
                                id_client_address: +hour.IdClientAddress,
                                modification_date: hour.ModificationDate,
                                modification_user_id: +hour.ModificationUserId,
                                open: +hour.Open === 1,
                            }
                            hours.push(formattedHour)
                        })
                        this.businessHours = hours
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/client/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/client/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const client = res.data
                        // dispatch('fetchClientContacts', payload).then(
                        // (contactsRes) => {
                        //     const contacts = contactsRes.data
                        const current = {
                            company_name: client.Name,
                            id: +client.Id,
                            address: `${client.Address1}`,
                            address2: `${client.Address2}`,
                            address3: `${client.Address3}`,
                            address4: `${client.Address4}`,
                            postal_code: client.PostalCode,
                            city: client.City,
                            country: client.Country,
                            registration_number: +client.Siret,
                            client_type: client.ClientType,
                            payment_type: client.PaymentType,
                            type: client.Type,
                            creation_date: client.CreationDate,
                            email: client.Email,
                            vat_intra: client.VatIntra,
                            rcs: client.RCS,
                            universeId: +client.IdUniverse,
                            // contact_firstname: client.FirstName,
                            // contact_lastname: client.LastName,
                            // contact_email: client.Email,
                            // phone: client.Phone,
                            id_external: client.IdExternal,
                            id_user_facturation:
                                client.IdUserFacturation > 0
                                    ? +client.IdUserFacturation
                                    : null,
                            id_address_facturation:
                                client.IdAddressFacturation !== null &&
                                +client.IdAddressFacturation !== 0
                                    ? +client.IdAddressFacturation
                                    : null,
                            voucher_price: +client.VoucherPrice,
                            voucher_limit: +client.VoucherLimit,
                            informations: client.Informations,
                            creation_user_id: +client.CreationUserId,
                            billing_address: client.AddressFacturation ?? null,
                            billing_contact: client.UserFacturation,
                            default_container: +client.IdDefaultContainer,
                            notes: client.ContactClientHistory,
                            contracts: client.Contracts,

                            // contacts,
                        }
                        this.current = current
                        const contactClientHistoryStore =
                            useContactClientHistoryStore()

                        const communications = []
                        contactClientHistoryStore.all = null
                        client?.ContactClientHistory?.forEach((history) => {
                            const formattedHistory = {
                                id: +history.Id,
                                name: history.Title,
                                client: history.Client.Name,
                                client_type:
                                    history.Client.Type === 'customer'
                                        ? 'B2C'
                                        : history.Client.Type === 'business'
                                        ? 'B2B'
                                        : history.Client.Type,
                                contact: `${history.UserContact.FirstName} ${history.UserContact.LastName}`,
                                subInfoForListDisplay:
                                    history.UserContact.Email,
                                sales_person: `${history.UserSalesPerson.FirstName} ${history.UserSalesPerson.LastName}`,
                                meeting_date: history.MeetingDate,
                                description: history.Description,
                                pinned: history.Pinned,
                                active: history.Active,

                                type: history.Type,
                                files: history.Files,
                            }
                            communications.push(formattedHistory)
                        })
                        contactClientHistoryStore.all = communications
                        const contractStore = useContractStore()
                        const contracts = []

                        client?.Contracts?.forEach((contract) => {
                            const formattedContract = {
                                id: +contract.Id,
                                name: contract.Name,
                                id_client: +contract.IdClient,
                                client_name:
                                    this.current.company_name !== null
                                        ? this.current.company_name
                                        : this.i18n.global.$t('no_client'),
                                end_date: contract.EndDate,
                                contract_type: contract.Type,
                                signature_date: contract.SignatureDate,
                                notice: contract.NoticePeriod, // in weeks
                                contract_duration: contract.Duration, // in months
                                idUniverse: +contract.IdUniverse,
                                creation_date: contract.CreationDate,
                                alert_before_end: contract.AlertBeforeEnd, // in weeks
                                auto_renew: contract.AutomaticRenewal,
                                modification_date: contract.ModificationDate,
                            }
                            contracts.push(formattedContract)
                        })
                        contractStore.all = contracts
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneAddress(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/clientAddress/${payload.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const address = res.data
                        let current = null
                        if (address !== ' ') {
                            if (payload.full) {
                                this.fetchBusinessHours(address.Id)
                                this.fetchOne(address.IdClient)
                            }

                            current = {
                                id: address.Id,
                                // client: client.company_name,
                                name: address.Name,
                                address: `${address.Address1}`,
                                address2: `${address.Address2}`,
                                address3: `${address.Address3}`,
                                postal_code: address.PostalCode,
                                country: address.Country,
                                additional_info: address.AdditionalInformation,
                                driver_info: address.DriverInformation,
                                contact_name: address.ContactName,
                                contact_email: address.ContactEmail,
                                contact_phone: address.ContactPhone,
                                contact_name_imported:
                                    address.ContactNameImported,
                                contact_phone_imported:
                                    address.ContactPhoneImported,
                                waiting_time: +address.WaitingTime,
                                city: address.City,
                                lat: parseFloat(address.Latitude),
                                lng: parseFloat(address.Longitude),
                                creation_date: address.CreationDate,
                                creation_user_id: address.CreationUserId,
                                modification_user_id:
                                    address.ModificationuserId,
                                client_id: address.IdClient,
                                address_type: address.AddressType,
                                hours: address.Hours,
                            }
                        }
                        if (payload.origin) {
                            this.currentClientPickup = current
                        } else {
                            this.currentAddress = current
                        }

                        resolve(res)
                        // })

                        // })
                    })
                    .catch((err) => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        fetchOneAddressByIdClientAndNameAndAddress1AndPostalCode(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/clientAddress/getByClientMultiInfos?client=${payload.idClient}&name=${payload.name}&address1=${payload.address}&zip=${payload.zip}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        const address = res.data
                        let current = null
                        if (address !== ' ') {
                            if (payload.full) {
                                this.fetchBusinessHours(address.Id)
                                this.fetchOne(address.IdClient)
                            }

                            current = {
                                id: address.Id,
                                // client: client.company_name,
                                name: address.Name,
                                address: `${address.Address1}`,
                                address2: `${address.Address2}`,
                                address3: `${address.Address3}`,
                                postal_code: address.PostalCode,
                                country: address.Country,
                                additional_info: address.AdditionalInformation,
                                driver_info: address.DriverInformation,
                                contact_name: address.ContactName,
                                contact_email: address.ContactEmail,
                                contact_phone: address.ContactPhone,
                                contact_name_imported:
                                    address.ContactNameImported,
                                contact_phone_imported:
                                    address.ContactPhoneImported,
                                waiting_time: +address.WaitingTime,
                                city: address.City,
                                lat: parseFloat(address.Latitude),
                                lng: parseFloat(address.Longitude),
                                creation_date: address.CreationDate,
                                creation_user_id: address.CreationUserId,
                                modification_user_id:
                                    address.ModificationuserId,
                                client_id: address.IdClient,
                                address_type: address.AddressType,
                                hours: address.Hours,
                            }
                        }
                        if (payload.origin) {
                            this.currentClientPickup = current
                        } else {
                            this.currentAddress = current
                        }

                        resolve(res)
                        // })

                        // })
                    })
                    .catch((err) => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        searchContainersByClient(payload) {
            const userStore = useUserStore()
            this.fetchError = false

            this.loading = true

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/${payload.clientId}/containers?format=pagination&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/${payload.clientId}/containers?format=pagination&query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // console.log(res)
                        if (res && res.status === 200) {
                            const containers = []
                            res.data.Records.forEach(async (container) => {
                                const formattedContainer = {
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet' ||
                                        container.Name === 'envelope'
                                            ? this.i18n.global.t(container.Name)
                                            : container.Name,
                                    id: +container.Id,
                                    client: +container.IdClient,
                                    size: `${container.Length}cm x ${container.Width}cm x ${container.Height} cm`,
                                    owned_by:
                                        (+container.IdClient === 0 &&
                                            userStore.isB2C === false) ||
                                        +container.IdClient ===
                                            +payload.clientId
                                            ? this.i18n.global.t(
                                                  'my_package_format'
                                              )
                                            : this.i18n.global.t(
                                                  'global_pack_format'
                                              ),
                                    active: +container.Active,
                                    creation_date: container.CreationDate,
                                    description: container.Description,
                                    creation_user_id: +container.CreationUserId,
                                    multiParamItemAction: true,
                                }
                                containers.push(formattedContainer)
                            })
                            this.containers = containers
                            this.containersCount = +res.data.TotalResults
                            this.containersTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.containers = null
                            this.containersCount = 0
                            this.containersTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        deleteOne({ commit }, payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/client/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deactivateContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/container/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        activateContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(`/api/v1/container/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        setNewDefaultContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(
                        `/api/v1/client/${payload.IdClient}/setDefaultContainerForClient`,
                        payload.form,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (payload.reloadClient) {
                            this.fetchOne(payload.IdClient).then(() => {
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteOneDeliveryAddress(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/clientAddress/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentAddress = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchClientContacts(payload) {
            this.loading = true
            // this.currentContacts = null
            // this.currentClientContactIds = null
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/client/${payload.clientId}/contacts`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res.data.TotalResults > 0) {
                            payload.contactIds = []
                            res.data.Records.forEach((contact) => {
                                payload.contactIds.push(contact.IdUser)
                            })
                            this.currentClientContactIds = payload.contactIds

                            if (
                                !payload.fromOrders ||
                                payload.fromOrders === false
                            ) {
                                this.searchClientContacts(payload).then(
                                    resolve(res)
                                )
                            } else {
                                const contacts = []
                                const count = +res.data.TotalResults
                                const totalPages = +res.data.TotalPages
                                res.data.Records.forEach((contact) => {
                                    const client = this.current

                                    if (client) {
                                        const formattedContact = {
                                            id: +contact.Id,
                                            client: client.company_name,
                                            email_address: contact.Email,
                                            firstname: contact.FirstName,
                                            lastname: contact.LastName,
                                            name: `${contact.FirstName} ${contact.LastName}`,
                                            phone: contact.Phone
                                                ? contact.Phone
                                                : 'No data to display',
                                            mobile: contact.MobilePhone
                                                ? contact.MobilePhone
                                                : 'No data to display',
                                            creation_date: contact.CreationDate,
                                        }
                                        contacts.push(formattedContact)
                                    }
                                })
                                this.currentContacts = contacts
                                this.currentContactsCount = count
                                this.totalPages = totalPages
                                this.loading = false
                                resolve(res)
                            }
                        } else {
                            this.loading = false
                            this.currentContactsCount = 0
                            resolve(res)
                        }
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        async searchClientContacts(payload) {
            this.loading = true
            this.fetchError = false

            let query = []

            query.push(
                `{"Id":[{"operator":"IN","value":[${payload.contactIds}]}]}`
            )

            if (payload.query && payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter && payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    query.push(
                        `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                    )
                })
            }
            // ? just in case...
            query.push('{"Type":[{"operator": "LIKE", "value":"client"}]}')

            const sortDirection = payload.sortDirection || 'ASC'
            const sortField = payload.sortField || 'CreationDate'
            const perPage = payload.perPage || 10
            const page = payload.page || 1
            let apiUrl = ''

            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/user/search?nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            } else {
                apiUrl = `/api/v1/user/search?query={"Query":[${query}]}&nbPP=${perPage}&page=${page}&order=${sortDirection}&sort=${sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const contacts = []
                            const count = +res.data.TotalResults
                            const totalPages = +res.data.TotalPages
                            res.data.Records.forEach((contact) => {
                                const client = this.current

                                if (client) {
                                    const formattedContact = {
                                        id: +contact.Id,
                                        client: client.company_name,
                                        email_address: contact.Email,
                                        firstname: contact.FirstName,
                                        lastname: contact.LastName,
                                        name: `${contact.FirstName} ${contact.LastName}`,
                                        phone: contact.Phone
                                            ? contact.Phone
                                            : 'No data to display',
                                        mobile: contact.MobilePhone
                                            ? contact.MobilePhone
                                            : 'No data to display',
                                        creation_date: contact.CreationDate,
                                    }
                                    contacts.push(formattedContact)
                                }
                            })
                            this.currentContacts = contacts
                            this.currentContactsCount = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.currentContacts = null
                            this.currentContactsCount = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })

                    .catch((err) => {
                        this.loading = false

                        reject(err)
                    })
            })
        },
        fetchContainers(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/client/${payload}/containers?query={"Query":[{"Active":[{"operator":"=","value":"1"}]}]}&nbPP=1000`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.containers = res.data.Records
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        createContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/container', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentContainer = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/container/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (payload.form.IsClientDefault === true) {
                            this.fetchOne(payload.form.IdClient).then(() => {
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },

        fetchOneContainer(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/container/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentContainer = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateClientPaymentType(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(`/api/v1/client/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchClientPricing(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/client/${payload.clientId}/prices?type=${payload.type}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})
export { useClientStore }
