<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <!-- <div class="mt-16"></div>
        <fa-icon :icon="['fab', 'grav']" class="fa-10x" />
        <div class="text-2xl font-bold">Coming soon...</div> -->
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'universes'" />
            <filtrable-table
                v-else
                id="universesList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="universes"
                :columns="columns"
                :storeToSort="'universe'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateUniverse()"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </div>

        <modal
            v-if="showUniverseModal"
            :headerText="universeModalHeader"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeUniverseModal()"
            @sendFromModal="submitUniverseModal()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-8">
                    <label class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('name') + ' *' }} <br />
                    </label>
                    <input
                        v-model="universeForm.Name"
                        autocomplete="name"
                        maxlength="100"
                        @blur="v$.universeForm.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.universeForm.Name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>

                    <label class="basis-1/4 text-gray-600 pb-1 pt-2 mr-2 block"
                        >{{ $t('description') + ' *' }} <br />
                    </label>
                    <textarea
                        v-model="universeForm.Description"
                        @blur="v$.universeForm.Description.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.universeForm.Description.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <span
                    class="flex mt-1 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
        <modal
            v-if="showDeleteConfirmModal"
            :headerText="deleteModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="deleteConfirmation(false)"
            @sendFromModal="deleteConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center mt-8">
                        {{ $t(deleteModalText) }}
                    </div>

                    <div
                        v-for="(toDelete, index) in elementToDelete"
                        :key="index"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span class="mr-1">
                                    {{ toDelete }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex text-xs italic justify-center mt-4">
                        {{ $t('delete_modal_generic_text_universe') }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'

import Modal from '@/components/atoms/Modal.vue'

import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useUniverseStore } from '@/stores/universeStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'UniversesList',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,

            showDeleteConfirmModal: false,
            confirmDelete: null,
            elementToDelete: [],
            deleteModalHeader: '',
            deleteModalText: '',
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'ModificationDate',
            perPage: 10,
            page: 1,
            universeInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                {
                    name: this.$t('ModificationDate'),
                    value: 'ModificationDate',
                },
            ],

            universeForm: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Description: '',
            },
            showUniverseModal: false,
            universeModalHeader: 'new_universe',
            searchingCursor: false,
        }
    },
    watch: {
        universes() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        const filteredList = this.$cookies.get('filteredUniverses')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.searchUniverses(realSearch)
        this.universeInterval = setInterval(() => {
            this.searchUniverses(realSearch)
        }, 20000)

        window.scrollTo(0, 0)
    },
    validations() {
        return {
            universeForm: {
                Name: { required: requiredIf(this.showUniverseModal === true) },
                Description: {
                    required: requiredIf(this.showUniverseModal === true),
                },
            },
        }
    },
    computed: {
        universesActionsAccess() {
            return actionsAccess.universes
        },
        ...mapStores(useUserStore),
        ...mapState(useUniverseStore, {
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapWritableState(useUniverseStore, {
            current: 'current',
            universes: 'all',
            count: 'count',
        }),

        columns() {
            return [
                {
                    name: 'Name',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.universesActionsAccess.update
                    ),
                    // routeName: 'update_role',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },

                {
                    name: 'Description',
                    filtrable: true,
                    type: 'search',
                    sortable: true,
                    dbField: 'Description',
                },

                {
                    name: 'ModificationDate',
                    sortable: true,
                    dbField: 'ModificationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.universesActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.universesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.universesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },

    methods: {
        upFilter(event) {
            clearInterval(this.universeInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.universesActionsAccess.create
            )
        },
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
            delete: 'delete',
            fetchOne: 'fetchOne',
            update: 'update',
            create: 'create',
        }),

        toCreateUniverse() {
            this.current = null
            this.universeModalHeader = 'new_universe'
            this.showUniverseModal = true
        },
        goToUpdateOne(id) {
            this.fetchOne(id).then((res) => {
                this.universeForm = {
                    Id: this.current.Id,
                    Name: this.current.Name,
                    CreationUserId: this.current.CreationUserId,
                    CreationDate: this.current.CreationDate,
                    Description: this.current.Description,
                }

                this.universeModalHeader = 'update_universe'
                this.showUniverseModal = true
                this.editUniverse = true
            })
        },
        notPickableNames(count, store, currentCodeId = null) {
            const result = []
            if (count > 0) {
                store.forEach((st) => {
                    if (currentCodeId === null || currentCodeId !== st.Id) {
                        result.push(st.Name.toLowerCase())
                    }
                })
            }
            return result
        },
        async submitUniverseModal() {
            this.creating = true

            const nameIsAvailable = !this.notPickableNames(
                this.count,
                this.universes,
                this.universeForm.Id
            ).includes(this.universeForm.Name.toLowerCase())

            if (nameIsAvailable === false) {
                this.creating = false
                this.$toast.error(this.$t('universe_already_exists'))
                return
            }

            const isValid = await this.v$.$validate()

            if (isValid === true) {
                if (this.editUniverse === true) {
                    this.universeForm.ModificationUserId =
                        this.userStore.current.id
                    const response = await this.update({
                        id: this.universeForm.Id,
                        form: this.universeForm,
                    })
                    if (response.status === 200) {
                        this.creating = false

                        this.setResults()
                        this.$toast.success(this.$t('update_ok'))
                        this.closeUniverseModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.universeForm.CreationUserId = this.userStore.current.id

                    const response = await this.create(this.universeForm)
                    if (response.status === 200) {
                        this.creating = false

                        this.setResults()

                        this.$toast.success(this.$t('create_ok'))

                        this.closeUniverseModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeUniverseModal() {
            this.current = null
            this.creating = false
            this.v$.universeForm.$reset()
            this.showUniverseModal = false
            this.editUniverse = false
            this.universeForm = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Description: null,
            }
        },
        async deleteOne(id) {
            this.confirmDelete = null

            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = []

            const toDelete = this.universes.find(
                (universe) => universe.Id === id
            )
            this.elementToDelete.push(toDelete.Name)

            const deleteInterval = setInterval(async () => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true

                        const response = await this.delete(id)
                        if (response.status === 200) {
                            this.setResults()
                            this.searchingCursor = false
                            this.$toast.success(this.$t('delete_ok'))
                        } else {
                            this.searchingCursor = false

                            this.$toast.error(this.$t('delete_ko'))
                        }
                    }
                }
            }, 150)
        },

        deleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.searchingCursor = confirmDelete
            this.showDeleteConfirmModal = false
        },
        deleteAll(elementsTodelete) {
            this.confirmDelete = null

            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text_plural'
            this.elementToDelete = []
            elementsTodelete.forEach((el) => {
                const toDelete = this.universes.find(
                    (universe) => universe.Id === el
                )
                this.elementToDelete.push(toDelete.Name)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        elementsTodelete.forEach((el, index) => {
                            this.searchingCursor = true

                            this.delete(el).then(() => {
                                if (index === elementsTodelete.length - 1) {
                                    this.searchingCursor = false
                                    this.setResults()
                                }
                            })
                        })
                    }
                }
            }, 150)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.universeInterval)
            this.$cookies.remove('filteredUniverses')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.universeInterval)
            this.$cookies.remove('filteredUniverses')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.searchUniverses(search).then(() => {
                this.refresh += 1
                this.searchingCursor = false
            })

            this.$cookies.set('filteredUniverses', search)
            this.universeInterval = setInterval(() => {
                this.searchUniverses(search)
            }, 20000)

            window.scrollTo(0, 0)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.universeInterval)
            this.$cookies.remove('filteredUniverses')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        setResults() {
            clearInterval(this.universeInterval)
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        clearInterval(this.universeInterval)
        this.current = null
        this.count = null
    },
}
</script>
