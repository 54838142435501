<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />
        <div
            id="create-user"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full w-1/2 flex justify-center"
        >
            <div>
                <div class="p-5">
                    <form
                        @keydown.enter.prevent
                        @submit.prevent="
                            $route.params.id ? updateUser() : createUser()
                        "
                    >
                        <div>
                            <div class="rounded-md p-4">
                                <div
                                    :class="
                                        userForm.Type !== 'operator' &&
                                        createMatching === true
                                            ? 'grid-cols-2 grid gap-2'
                                            : ''
                                    "
                                >
                                    <div
                                        class="rounded-md border-2 p-4 col-span-1"
                                    >
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{ $t('account_informations') }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div
                                            class="grid grid-cols-8 gap-2 mt-6"
                                        >
                                            <div class="col-span-2">
                                                <div
                                                    id="email"
                                                    class="mb-3 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 text-xs pb-1 mr-2 block"
                                                        >{{
                                                            ` ${$t('email')}  *`
                                                        }}
                                                    </label>

                                                    <input
                                                        type="email"
                                                        autocomplete="email"
                                                        @focus="
                                                            userAlreadyExists = false
                                                        "
                                                        @blur="
                                                            v$.userForm.Email.$touch()
                                                        "
                                                        v-model="userForm.Email"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                        :class="
                                                            userAlreadyExists
                                                                ? 'border-red-500 text-red-500'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .Email.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <div
                                                    id="login"
                                                    class="mb-3 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 text-xs pb-1 mr-2 block"
                                                        >{{
                                                            ` ${$t(
                                                                'login_only'
                                                            )} *`
                                                        }}
                                                    </label>

                                                    <input
                                                        type="login"
                                                        autocomplete="email"
                                                        @blur="
                                                            v$.userForm.Login.$touch()
                                                        "
                                                        v-model="userForm.Login"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .Login.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <div class="mb-3 items-center">
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 text-xs pb-1 mr-2 block"
                                                        >{{
                                                            $t('firstname') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        v-model="
                                                            userForm.FirstName
                                                        "
                                                        @blur="
                                                            v$.userForm.FirstName.$touch()
                                                        "
                                                        autocomplete="name"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .FirstName.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <div class="mb-3 items-center">
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 text-xs pb-1 mr-2 block"
                                                        >{{
                                                            $t('lastname') +
                                                            ' *'
                                                        }}
                                                    </label>
                                                    <input
                                                        autocomplete="name"
                                                        v-model="
                                                            userForm.LastName
                                                        "
                                                        @blur="
                                                            v$.userForm.LastName.$touch()
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .LastName.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="grid grid-cols-6 gap-2 mt-6"
                                        >
                                            <div class="col-span-3">
                                                <div class="mb-3 items-center">
                                                    <label
                                                        class="font-semibold text-gray-600 text-xs basis-1/4 pb-1 mr-2 block"
                                                        >{{
                                                            $t('phone')
                                                        }}</label
                                                    >
                                                    <input
                                                        type="tel"
                                                        v-model="userForm.Phone"
                                                        @blur="
                                                            v$.userForm.Phone.$touch()
                                                        "
                                                        autocomplete="tel"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .Phone.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <div class="mb-3 items-center">
                                                    <label
                                                        class="font-semibold text-gray-600 text-xs basis-1/4 pb-1 mr-2 block"
                                                        >{{
                                                            $t('mobile')
                                                        }}</label
                                                    >
                                                    <input
                                                        type="tel"
                                                        v-model="
                                                            userForm.MobilePhone
                                                        "
                                                        @blur="
                                                            v$.userForm.MobilePhone.$touch()
                                                        "
                                                        autocomplete="tel"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .MobilePhone.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span
                                            v-if="isPersonnalAccount === true"
                                        >
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('password') + ' *'
                                                    }}</label
                                                >
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 right-0 flex items-center px-2"
                                                    >
                                                        <icon-button
                                                            id="eye"
                                                            class="ml-2 cursor-pointer"
                                                            @click="
                                                                togglePasswordVisibility()
                                                            "
                                                            :icon="
                                                                passwordVisibility
                                                            "
                                                            :noStyle="true"
                                                            :iconStyle="'text-zinc-500 pt-2'"
                                                        ></icon-button>
                                                    </div>
                                                    <input
                                                        id="passwordInput"
                                                        v-model="password"
                                                        tabindex="1"
                                                        :type="passwordType"
                                                        autocomplete="new-password"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                            </div>
                                            <password-score
                                                id="pwdStrength"
                                                :value="password"
                                                class="mt-2 mb-6"
                                                @passed="
                                                    isPasswordStrong = true
                                                "
                                                @failed="
                                                    isPasswordStrong = false
                                                "
                                            />
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('confirm_password') +
                                                        ' *'
                                                    }}</label
                                                >
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 right-0 flex items-center px-2"
                                                    >
                                                        <icon-button
                                                            @click="
                                                                togglePasswordVisibility()
                                                            "
                                                            :icon="
                                                                passwordVisibility
                                                            "
                                                            class="ml-2 cursor-pointer"
                                                            :noStyle="true"
                                                            :iconStyle="'text-zinc-500 pt-2'"
                                                        ></icon-button>
                                                    </div>
                                                    <input
                                                        id="confirmPasswordInput"
                                                        autocomplete="new-password"
                                                        tabindex="2"
                                                        v-model="
                                                            confirmPassword
                                                        "
                                                        :type="passwordType"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />

                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .confirmPassword
                                                            .$errors"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{
                                                                $t(
                                                                    'passwords_dont_match'
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                            >
                                                {{ $t('pw_notice') }}
                                            </div>
                                            <button
                                                type="button"
                                                @click="
                                                    isPersonnalAccount =
                                                        !isPersonnalAccount
                                                "
                                                class="themed-button text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                {{
                                                    $t('for_other_account_btn')
                                                }}
                                            </button>
                                        </span>
                                        <span
                                            v-else-if="$route.params.id"
                                            class="flex justify-center"
                                        >
                                            <button
                                                type="button"
                                                @click="
                                                    sendLink(
                                                        userForm.Email,
                                                        false
                                                    )
                                                "
                                                class="themed-button text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                {{ $t('send_rp_link') }}
                                            </button>
                                        </span>
                                        <span
                                            v-else
                                            class="flex justify-center"
                                        >
                                            <button
                                                type="button"
                                                @click="
                                                    isPersonnalAccount =
                                                        !isPersonnalAccount
                                                "
                                                class="themed-button text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                {{ $t('own_account_btn') }}
                                            </button>
                                        </span>
                                        <!-- <div
                                            id="email"
                                            class="mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                >{{ $t('email') }}
                                            </label>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                            >
                                                {{ $t('email_used_for_login') }}
                                            </div>
                                            <input
                                                type="email"
                                                autocomplete="email"
                                                @blur="v$.userForm.Email.$touch"
                                                v-model="userForm.Email"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div> -->
                                        <div
                                            class="mb-2 items-center mt-2 mx-8"
                                        >
                                            <div>
                                                <label
                                                    class="font-semibold text-gray-600 whitespace-nowrap text-xs pb-1 mr-6 block"
                                                    >{{
                                                        $t('user_type') + ' *'
                                                    }}</label
                                                >
                                                <Multiselect
                                                    class="border darky rounded px-3 py-2 mt-1 w-full"
                                                    v-model="userForm.Type"
                                                    :options="typeOptions"
                                                    :searchable="false"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                    @select="
                                                        setCreateMatching()
                                                    "
                                                ></Multiselect>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1"
                                            v-for="error of v$.userForm.Type
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                        <div
                                            class="mb-2 items-center mt-2 mx-8"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 whitespace-nowrap text-xs pb-1 mr-6 block"
                                                >{{
                                                    $tc('universes', 2) + ' *'
                                                }}</label
                                            >
                                            <!-- TODO: add Checkboxes -->
                                            <Multiselect
                                                class="border darky rounded px-3 py-2 mt-1 w-full"
                                                v-model="userUniverses"
                                                mode="tags"
                                                :close-on-select="false"
                                                :searchable="true"
                                                :create-option="true"
                                                :options="universeOptions"
                                                :allow-empty="false"
                                                :can-deselect="false"
                                                :can-clear="false"
                                                label="name"
                                                track-by="name"
                                            ></Multiselect>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1"
                                            v-for="error of v$.userForm
                                                .Universes.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                        <span
                                            class="items-center justify-center mt-2 flex pb-2"
                                        >
                                            {{ `${$t('add_roles_to_user')}` }}
                                        </span>
                                        <div
                                            class="border-t-2 border-b-2 themed-border mb-2 h-40 overflow-y-auto"
                                        >
                                            <div
                                                v-for="(role, index) in roles"
                                                :key="index"
                                                :class="
                                                    index === roles.length - 1
                                                        ? 'mb-1'
                                                        : ''
                                                "
                                            >
                                                <div
                                                    class="flex items-center mt-1 justify-start"
                                                >
                                                    <input
                                                        :id="`checkbox-role-${index}`"
                                                        type="checkbox"
                                                        v-model="
                                                            isCheckedRole(
                                                                role.Id
                                                            ).value
                                                        "
                                                        @change="
                                                            checkRole(role.Id)
                                                        "
                                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                    />
                                                    <label
                                                        :for="`checkbox-role-${index}`"
                                                        class="ml-2 font-medium"
                                                    >
                                                        {{
                                                            `${role.Name} : ${role.Description}`
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            userForm.Type !== 'operator' &&
                                            createMatching === true
                                        "
                                        class="rounded-md border-2 p-4 col-span-1"
                                    >
                                        <div class="px-3">
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{ $t('personnal_infos') }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <span
                                                v-if="
                                                    userForm.Type === 'client'
                                                "
                                            >
                                                <div
                                                    id="name"
                                                    class="mb-3 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                        >{{
                                                            $t('company_name') +
                                                            ' *'
                                                        }}
                                                    </label>
                                                    <input
                                                        autocomplete="name"
                                                        v-model="
                                                            userForm.Client.Name
                                                        "
                                                        @blur="
                                                            v$.userForm.Client.Name.$touch()
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.userForm
                                                        .Client.Name.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </span>

                                            <div
                                                id="address"
                                                class="mb-3 items-center"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{ $t('address') + ' *' }}
                                                </label>
                                                <input
                                                    id="autocomplete"
                                                    v-model="
                                                        userForm.Client.Address1
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="postal_code"
                                                    v-model="
                                                        userForm.Client
                                                            .PostalCode
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.PostalCode.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{ $t('city') + ' *' }}
                                                </label>
                                                <input
                                                    autocomplete="city"
                                                    v-model="
                                                        userForm.Client.City
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.City.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span
                                class="flex mt-1 italic text-xs items-center justify-center"
                            >
                                {{ $t('required_fields') }}
                            </span>
                            <div class="p-2 mt-4">
                                <div class="flex justify-center">
                                    <button
                                        type="button"
                                        @click="back()"
                                        class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                    >
                                        {{ $t('cancel') }}
                                    </button>
                                    <button
                                        type="submit"
                                        :disabled="creating"
                                        class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span v-if="creating">
                                            <fa-icon
                                                icon="spinner"
                                                spin-pulse
                                            ></fa-icon
                                        ></span>
                                        <span v-else>
                                            {{
                                                $route.params.id
                                                    ? $t('update')
                                                    : isPersonnalAccount ===
                                                      true
                                                    ? $t('create')
                                                    : $t('create_and_send_mail')
                                            }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import IconButton from '@/components/atoms/IconButton.vue'
import PasswordScore from '@/components/atoms/PasswordScore.vue'
import BackTo from '@/components/elements/BackTo.vue'
import googleScriptLoader from '@/mixins/googleScript'
import keys from '@/resources/keys'
import { useAllUsersStore } from '@/stores/allUsersStore'
import { useDriverStore } from '@/stores/driverStore'
import { useRoleStore } from '@/stores/roleStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required, requiredIf, sameAs } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { ref } from 'vue'

const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
)
const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)
export default {
    name: 'CreateOrUpdate',
    components: { Multiselect, BackTo, PasswordScore, IconButton },
    mixins: [googleScriptLoader],

    setup() {
        const isPasswordStrong = ref(false)
        return { isPasswordStrong, v$: useVuelidate() }
    },
    created() {
        window.scrollTo(0, 0)
        if (
            this.connectedUser.type === 'admin' ||
            this.connectedUser.type === 'superadmin'
        ) {
            const universeSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: 10000,
                page: 1,
                query: [],
                filter: [],
            }
            this.searchUniverses(universeSearch).then((res) => {
                this.universes.forEach((universe) => {
                    this.universeOptions.push({
                        name: universe.Name,
                        value: universe.Id,
                    })
                })
            })
        } else {
            this.connectedUserUniverses.forEach((universe) => {
                this.universeOptions.push({
                    name: universe.name,
                    value: universe.value,
                    dbField: 'IdUniverse',
                    realName: universe.name,
                })
            })
        }
        this.currentUserInEditionInterval = setInterval(() => {
            if (this.currentUserInEdition) {
                clearInterval(this.currentUserInEditionInterval)

                this.userForm = {
                    FirstName: this.currentUserInEdition.FirstName,
                    LastName: this.currentUserInEdition.LastName,
                    Id: this.currentUserInEdition.Id,
                    Type: this.currentUserInEdition.Type,
                    CreationUserId: this.currentUserInEdition.CreationUserId,
                    CreationDate: this.currentUserInEdition.CreationDate,
                    MobilePhone: this.currentUserInEdition.MobilePhone,
                    Phone: this.currentUserInEdition.Phone,
                    Email: this.currentUserInEdition.Email,
                    Login: this.currentUserInEdition.Login,
                    IdClient: this.currentUserInEdition.IdClient,
                    Universes: [],

                    // IdUniverse: this.currentUserInEdition.IdUniverse,
                    // Universes: this.currentUserInEdition.Universes,
                    // IdDriver: this.currentUserInEdition.IdDriver,
                }
                // TODO faire pour universe que que l'on fait pour roles
                this.currentUserInEdition.Roles.forEach((role) => {
                    this.checkRole(role.Id)
                })
                // if (this.currentUserInEdition.Universes.length > 0) {
                //     const formattedUniverses = []
                //     this.currentUserInEdition.Universes.forEach((u) => {
                //         formattedUniverses.push({ Id: u.Id })
                //     })
                //     this.currentUserInEdition.Universes = []
                //     this.userUniverses = formattedUniverses
                // }
                this.currentUserInEdition.Universes.forEach((u) => {
                    this.selectedUniverses(u.Id)
                })
            }
        }, 1000)
        const roleSearch = {
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10000,
            page: 1,
            query: [],
            filter: [],
        }
        this.searchRoles(roleSearch)
    },
    data() {
        return {
            userRoleIds: [],
            userUniverses: [],
            userAlreadyExists: false,
            center: { lat: 24.886, lng: -70.268 },
            creating: false,
            createMatching: false,
            currentUserInEditionInterval: null,
            isBike: false,
            infoWindow: null,
            password: '',
            universeOptions: [],
            isPersonnalAccount: false,
            confirmPassword: '',
            passwordType: 'password',
            passwordVisibility: 'eye',
            userForm: {
                Type: 'operator',
                LastName: '',
                FirstName: '',
                Phone: '',
                Password: '',
                Login: '',
                MobilePhone: '',
                Email: '',
                CreationUserId: '',
                Universes: [],
                Client: {
                    Name: '',
                    ClientType: 'occasional',
                    Address1: '',
                    City: '',
                    PostalCode: '',
                    Country: 'FRA',
                    Email: '',
                    Type: 'customer',
                    PaymentType: 'web',
                    CreationUserId: 1,
                    IdUniverse: 0,
                },
                ModificationUserId: '',
            },
        }
    },
    validations() {
        return {
            password: requiredIf(
                this.isPersonnalAccount === true && !this.$route.params.id
            ),
            confirmPassword: { sameAs: sameAs(this.password) },
            userForm: {
                Email: { required },
                Type: { required },
                Universes: { required },
                Login: { required },
                FirstName: { required },
                LastName: { required },
                Phone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
                MobilePhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
                Client: {
                    Name: {
                        required: requiredIf(
                            this.userForm.Type === 'client' &&
                                this.createMatching === true &&
                                !this.$route.params.id
                        ),
                    },
                    Address1: {
                        required: requiredIf(
                            this.userForm.Type !== 'operator' &&
                                this.createMatching === true &&
                                !this.$route.params.id
                        ),
                    },
                    PostalCode: {
                        required: requiredIf(
                            this.userForm.Type !== 'operator' &&
                                this.createMatching === true &&
                                !this.$route.params.id
                        ),

                        isZipCode: helpers.withMessage(
                            this.$t('bad_zip_format'),
                            isZipCode
                        ),
                    },

                    City: {
                        required: requiredIf(
                            this.userForm.Type !== 'operator' &&
                                this.createMatching === true &&
                                !this.$route.params.id
                        ),
                    },
                },
            },
        }
    },
    computed: {
        ...mapWritableState(useAllUsersStore, {
            currentUserInEdition: 'currentUserInEdition',
        }),
        ...mapState(useUserStore, {
            connectedUser: 'current',
            connectedUserUniverses: 'userUniverses',
        }),
        ...mapState(useRoleStore, {
            roles: 'all',
        }),
        ...mapState(useUniverseStore, {
            universes: 'all',
        }),

        typeOptions() {
            const types = [
                {
                    name: this.$t('operator'),
                    value: 'operator',
                }, // headset
                {
                    name: this.$t('driver'),
                    value: 'driver',
                },
                { name: this.$t('client'), value: 'client' },
            ]
            if (this.connectedUser && this.connectedUser.type === 'admin') {
                types.push({ name: this.$t('admin'), value: 'admin' })
            }
            if (
                this.connectedUser &&
                this.connectedUser.type === 'superadmin'
            ) {
                types.push({ name: this.$t('admin'), value: 'admin' })
                types.push({
                    name: this.$t('super_admin'),
                    value: 'superadmin',
                })
            }
            return types
        },
    },
    watch: {
        // 'userForm.Type': function () {
        //     if (
        //         this.userForm.Type === 'client' ||
        //         this.userForm.Type === 'driver'
        //     ) {
        //         this.createMatchng = true
        //         this.loadGoogle()
        //     } else {
        //         this.createMatching = false
        //     }
        // },

        isPersonnalAccount() {
            this.v$.$reset()
            if (this.isPersonnalAccount === false) {
                this.password = ''
                this.confirmPassword = ''
            }
        },
    },
    methods: {
        ...mapActions(useDriverStore, {
            createDriver: 'create',
        }),
        ...mapActions(useRoleStore, {
            searchRoles: 'search',
        }),
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useUserStore, [
            'forgotPassword',
            'create',
            'refreshConnectedUser',
        ]),
        ...mapActions(useAllUsersStore, {
            updateCurrentUser: 'update',
        }),
        setCreateMatching() {
            if (
                this.userForm.Type === 'client' ||
                this.userForm.Type === 'driver'
            ) {
                this.createMatching = true
                this.loadGoogle()
            } else {
                this.createMatching = false
            }
        },
        isCheckedRole(roleId) {
            if (this.userRoleIds.find((el) => el === roleId)) {
                return { value: true }
            }
            return { value: false }
        },
        selectedUniverses(universeId) {
            const universeIndex = this.userUniverses.findIndex(
                (el) => +el === +universeId
            )
            if (universeIndex === -1) {
                this.userUniverses.push(+universeId)
            } else {
                this.userUniverses.splice(universeIndex, 1)
            }
        },
        checkRole(roleId) {
            const roleIndex = this.userRoleIds.findIndex((el) => el === roleId)
            if (roleIndex === -1) {
                this.userRoleIds.push(roleId)
            } else {
                this.userRoleIds.splice(roleIndex, 1)
            }
        },
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        loadGoogle() {
            if (
                this.userForm.Type !== 'operator' &&
                this.createMatching === true
            ) {
                this.loadGoogleScript('autocomplete')
            }
        },
        sendLink(email, RequestedByOperatorOnAccountCreation = false) {
            this.forgotPassword({
                Email: email,
                Requester: RequestedByOperatorOnAccountCreation
                    ? this.connectedUser.id
                    : '',
            })
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t('reset_password_link_sent'))
                    } else {
                        this.$toast.error(
                            this.$t('reset_password_link_not_sent')
                        )
                    }
                })
                .catch((error) => {
                    this.$toast.error(this.$t('reset_password_link_not_sent'))
                })
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the clientForm.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.userForm.Client.Address1 = ''

            this.userForm.Client.PostalCode = ''
            this.userForm.Client.City = ''

            const userInput = this.userForm.Client.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    this.userForm.Client.PostalCode = val
                } else if (addressType === 'locality') {
                    this.userForm.Client.City = val
                } else if (addressType === 'street_number') {
                    this.userForm.Client.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.userForm.Client.Address1 =
                        this.userForm.Client.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.userForm.Client.Address1} ${val}`
                            : `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.userForm.Client.Address1 === userInput
                ) {
                    this.userForm.Client.Address1 = ` ${val}`
                }
            })
        },
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.currentUserInEdition = null
            this.$emit('back', 'users')
        },

        async createUser() {
            this.creating = true
            this.userAlreadyExists = false
            this.v$.$reset()

            this.userForm.CreationUserId = this.connectedUser.id
            this.userForm.ModificationUserId = this.connectedUser.id
            this.userForm.Login = this.userForm.Email
            this.userForm.Password = this.password

            this.userUniverses.forEach((universeId) => {
                this.userForm.Universes.push({ Id: universeId })
            })
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                this.userForm.Roles = []
                this.userRoleIds.forEach((id) => {
                    this.userForm.Roles.push({ Id: id })
                })
                try {
                    let response = null
                    if (
                        this.userForm.Type === 'client' &&
                        this.createMatching === true
                    ) {
                        const key =
                            window.location.hostname === 'localhost'
                                ? 'localhost'
                                : window.location.host.substring(
                                      0,
                                      window.location.host.indexOf('.')
                                  )
                        if (
                            this.userForm.Client.Name === '' &&
                            this.userForm.LastName !== '' &&
                            this.userForm.FirstName !== ''
                        ) {
                            this.userForm.Client.Name = `${this.userForm.FirstName} ${this.userForm.LastName}`
                        }
                        this.userForm.key = `${keys[key]}`
                        this.userForm.Client.Email = this.userForm.Email
                        response = await this.create(this.userForm)
                    } else {
                        response = await this.create(this.userForm)
                    }
                    if (response.status === 200) {
                        if (
                            this.userForm.Type === 'driver' &&
                            this.createMatching === true
                        ) {
                            this.userForm.PhonePro = this.userForm.MobilePhone
                            this.userForm.PhonePerso = this.userForm.Phone
                            this.userForm.Active = true
                            this.userForm.ContactToWarnFirstName = ''
                            this.userForm.ContactToWarnLasttName = ''
                            this.userForm.ContactToWarnPhone1 = ''
                            this.userForm.ContactToWarnPhone2 = ''
                            this.userForm.SubContractor = false
                            this.userForm.IdVehicle = ''
                            this.userForm.IdUser = response.data.Id
                            this.userForm.IdSupplier = ''
                            this.userForm.IdSector = ''
                            this.userForm.Address2 = ''

                            this.userForm.GPSPointLatitudeStart = null
                            this.userForm.GPSPointLatitudeEnd = null
                            this.userForm.GPSPointLongitudeStart = null
                            this.userForm.GPSPointLongitudeEnd = null
                            this.userForm.Address1 =
                                this.userForm.Client.Address1

                            this.userForm.PostalCode =
                                this.userForm.Client.PostalCode
                            this.userForm.City = this.userForm.Client.City
                            this.createDriver(this.userForm)
                        }
                        if (this.isPersonnalAccount === false) {
                            this.sendLink(this.userForm.Email, true)
                        }
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)

                        this.$emit('created', 'users')
                        this.$toast.success(this.$t('user_create_ok'))
                        this.creating = false
                    } else {
                        this.creating = false
                        this.$toast.error(`${this.$t('user_create_ko')}`)
                    }
                } catch (err) {
                    this.creating = false
                    if (err.response.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.$toast.error(`${this.$t('user_create_ko')}`)
                        this.$toast.error(err.data.message)
                    }
                }
            } else {
                this.creating = false
                this.polygoneError = true
                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateUser() {
            this.v$.$reset()

            this.creating = true

            this.userUniverses.forEach((universeId) => {
                this.userForm.Universes.push({ Id: universeId })
            })
            const isFormCorrect = await this.v$.$validate()
            this.userForm.ModificationUserId = this.connectedUser.id

            if (isFormCorrect === true) {
                this.userForm.Roles = []
                this.userRoleIds.forEach((id) => {
                    this.userForm.Roles.push({ Id: id })
                })
                //! fonctionne dans la mesure ou le login est toujours l'email
                // this.userForm.Login = this.userForm.Email
                // TODO SI LE LOGIN EST === EMAIL ALORS ON PEUT FAIRE CA
                // LODO enlever la possibilité de modifier le login dans le formualaire my account
                const params = {
                    id: this.$route.params.id,
                    form: this.userForm,
                }
                try {
                    const response = this.updateCurrentUser(params)
                    if (response) {
                        this.refreshConnectedUser(true, true)
                        this.creating = false
                        this.$toast.success(this.$t('user_update_ok'))
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)

                        this.$emit('updated', 'users')
                    }
                } catch (err) {
                    this.creating = false
                    this.$toast.error(`${this.$t('user_update_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.currentUserInEdition = null
    },
}
</script>
