import { defineStore } from 'pinia'

const useDriverStore = defineStore('driver', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        currentPlanning: null,
        driversActivities: [],
        lastPosition: null,
        driversLastPositions: null,
        driversCalendar: [],
        traces: [],
    }),
    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'UserUniverse.IdUniverse') {
                        if (type.value.length > 0) {
                            query.push(
                                `{"User_Universe.IdUniverse":[{"operator":"IN","value": [${type.value}]}]}`
                            )
                        }
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"User_Universe.IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/driver/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/driver/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200 && res.data.Records) {
                            const drivers = []
                            res.data.Records.forEach(async (driver) => {
                                const formattedDriver = {
                                    id: +driver.Id,
                                    firstname: driver.FirstName,
                                    lastname: driver.LastName,
                                    user_id: driver.IdUser,
                                    address: `${driver.Address1} ${driver.Address2}`,
                                    postal_code: driver.PostalCode,
                                    city: driver.City,
                                    country: driver.Country,
                                    active: +driver.Active,
                                    vehicle: driver.idVehicle,
                                    contract_hour: driver.ContractHour
                                        ? driver.ContractHour
                                        : 0,
                                    creation_date: driver.CreationDate,
                                }

                                drivers.push(formattedDriver)
                            })

                            this.all = drivers
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                            if (payload.setCurrent && drivers.length === 1) {
                                this.current = drivers[0]
                            }
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/driver', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            this.lastPosition = null
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/driver/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const driver = res.data
                        this.fetchLastPosition(driver.Id).then(() => {
                            const current = {
                                id: +driver.Id,
                                value: +driver.Id,
                                name: `${driver.FirstName} ${driver.LastName}`,

                                firstname: driver.FirstName,
                                lastname: driver.LastName,
                                address: driver.Address1,
                                address2: driver.Address2,
                                postal_code: driver.PostalCode,
                                city: driver.City,
                                phone_pro: driver.PhonePro,
                                phone_perso: driver.PhonePerso,
                                creation_user_id: driver.CreationUserId,
                                modification_user_id: driver.ModificationUserId,
                                active: +driver.Active,
                                contact_first: driver.ContactToWarnFirstName,
                                contact_last: driver.ContactToWarnLastName,
                                contact_phone1: driver.ContactToWarnPhone1,
                                contact_phone2: driver.ContactToWarnPhone2,
                                contract_hour: driver.ContractHour
                                    ? driver.ContractHour
                                    : 0,

                                sub_contractor: +driver.SubContractor,
                                vehicle: +driver.IdVehicle,
                                user: +driver.IdUser,
                                supplier: +driver.IdSupplier,
                                area: +driver.IdSector,
                                lat_start: driver.GPSPointLatitudeStart,
                                long_start: driver.GPSPointLongitudeStart,
                                lat_end: driver.GPSPointLatitudeEnd,
                                long_end: driver.GPSPointLongitudeEnd,
                                last_position: this.lastPosition.Latitude
                                    ? {
                                          lat: +this.lastPosition.Latitude,
                                          lng: +this.lastPosition.Longitude,
                                      }
                                    : null,
                            }
                            this.current = current

                            resolve(res)
                        })
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/driver/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchLastPosition(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/driver/${payload}/lastPosition`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.lastPosition = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        allDriversLastPositions() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/driverPosition/lastPositions`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            this.driversLastPositions = res.data
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        deactivate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/driver/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        //                 @tours="toTours($event)"
        addPlanningBaseActivity(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/planningBase', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        addPlanningException(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/planningException', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        addPlanningCalendarActivity(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/planningCalendar', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deletePlanningCalendar(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/planningCalendar/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateBasePlanning(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/planningBase/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteBasePlanning(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/planningBase/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        availableDriversForDate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/driver/available?date=${payload.date}&universes=${payload.universes}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        // if (res && res.status === 200 && res.data) {
                        //     const drivers = []
                        //     res.data.forEach(async (driver) => {
                        //         const formattedDriver = {
                        //             id: +driver.Id,
                        //             firstname: driver.FirstName,
                        //             lastname: driver.LastName,
                        //             user_id: driver.IdUser,
                        //             address: `${driver.Address1} ${driver.Address2}`,
                        //             postal_code: driver.PostalCode,
                        //             city: driver.City,
                        //             country: driver.Country,
                        //             active: +driver.Active,
                        //             vehicle: driver.idVehicle,
                        //             contract_hour: driver.ContractHour
                        //                 ? driver.ContractHour
                        //                 : 0,
                        //             creation_date: driver.CreationDate,
                        //         }

                        //         drivers.push(formattedDriver)
                        //     })

                        //     this.all = drivers
                        //     this.count = +res.data.TotalResults
                        //     this.totalPages = +res.data.TotalPages
                        //     this.loading = false
                        //     // if (payload.setCurrent && drivers.length === 1) {
                        //     //     this.current = drivers[0]
                        //     // }
                        // } else {
                        //     this.all = null
                        //     this.count = 0
                        //     this.totalPages = 0
                        //     this.loading = false
                        // }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchDriversRealPlannings(payload) {
            this.driversCalendar = []
            this.driversActivities = []

            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/user/planningcalendars?datebegin=${payload.begin}&dateend=${payload.end}&type=driver&universes=${payload.universes}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (res && res.status === 200) {
                            if (res.data.length > 0) {
                                res.data.forEach((user) => {
                                    const activities = []
                                    if (user.PlanningCalendar.length > 0) {
                                        user.PlanningCalendar.forEach(
                                            (activity) => {
                                                if (
                                                    activity.IdUser &&
                                                    activity.Active === '1'
                                                ) {
                                                    activity.id = +activity.Id
                                                    activity.end =
                                                        activity.End.substring(
                                                            0,
                                                            5
                                                        )
                                                    activity.start =
                                                        activity.Start.substring(
                                                            0,
                                                            5
                                                        )
                                                    activity.list = `driver-${activity.IdUser}-activities`
                                                    activity.title =
                                                        activity.Name
                                                    activity.color =
                                                        activity.Color
                                                    activity.base_activity_id =
                                                        activity.IdPlanningActivity
                                                    activity.type =
                                                        activity.Type

                                                    activity.List = `driver-${activity.IdUser}-activities`
                                                    activity.End =
                                                        activity.End.substring(
                                                            0,
                                                            5
                                                        )
                                                    activity.Start =
                                                        activity.Start.substring(
                                                            0,
                                                            5
                                                        )
                                                    activity.basePlanning =
                                                        !!activity.IdPlanningBase

                                                    // les base planning n'ont pas le bon format de date
                                                    if (
                                                        !activity.Date.includes(
                                                            '-'
                                                        )
                                                    ) {
                                                        activity.Date = `${activity.Date.substring(
                                                            0,
                                                            4
                                                        )}-${activity.Date.substring(
                                                            4,
                                                            6
                                                        )}-${activity.Date.substring(
                                                            6,
                                                            activity.Date.length
                                                        )}`
                                                    }

                                                    activities.push(activity)
                                                    this.driversActivities.push(
                                                        activity
                                                    )
                                                }
                                            }
                                        )
                                    }
                                    if (user.Driver) {
                                        const driver = user.Driver
                                        driver.hours = {
                                            miss: driver.MissingHours,
                                            overtime: driver.Overtime,
                                            real: user.RealisedHours,
                                        }
                                        const real = {
                                            driver,
                                            activities,
                                        }
                                        this.driversCalendar.push(real)
                                    }
                                    // this.driversActivities.push(activities)
                                })
                            }
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchDriverRealPlanning(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/user/${payload.id}/planningcalendar?datebegin=${payload.begin}&dateend=${payload.end}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (res && res.status === 200) {
                            const toRemove = []
                            this.driversActivities.forEach(
                                (activity, index) => {
                                    if (activity.IdUser === payload.id) {
                                        toRemove.push(index)
                                    }
                                }
                            )
                            // delete backwards to avaoid messing table up
                            for (let i = toRemove.length - 1; i >= 0; i -= 1)
                                this.driversActivities.splice(toRemove[i], 1)
                            const activities = []
                            if (res.data.PlanningCalendar.length > 0) {
                                res.data.PlanningCalendar.forEach(
                                    (activity) => {
                                        if (
                                            activity.IdUser &&
                                            activity.Active === '1'
                                        ) {
                                            activity.id = +activity.Id
                                            activity.end =
                                                activity.End.substring(0, 5)
                                            activity.start =
                                                activity.Start.substring(0, 5)
                                            activity.list = `driver-${activity.IdUser}-activities`
                                            activity.title = activity.Name
                                            activity.color = activity.Color
                                            activity.base_activity_id =
                                                activity.IdPlanningActivity
                                            activity.type = activity.Type

                                            activity.List = `driver-${activity.IdUser}-activities`
                                            activity.End =
                                                activity.End.substring(0, 5)
                                            activity.Start =
                                                activity.Start.substring(0, 5)
                                            activity.basePlanning =
                                                !!activity.IdPlanningBase
                                            if (!activity.Date.includes('-')) {
                                                activity.Date = `${activity.Date.substring(
                                                    0,
                                                    4
                                                )}-${activity.Date.substring(
                                                    4,
                                                    6
                                                )}-${activity.Date.substring(
                                                    6,
                                                    activity.Date.length
                                                )}`
                                            }

                                            activities.push(activity)
                                            this.driversActivities.push(
                                                activity
                                            )
                                        }
                                    }
                                )
                            }

                            const driver = res.data.Driver
                            driver.hours = {
                                miss: driver.MissingHours,
                                overtime: driver.Overtime,
                                real: res.data.RealisedHours,
                            }

                            const real = {
                                driver,
                                activities,
                            }
                            this.driversCalendar.forEach((cal, index) => {
                                if (+cal.driver.IdUser === +payload.id) {
                                    this.driversCalendar[index] = real
                                }
                            })
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchDriverPlanning(payload) {
            // console.log(payload)
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/user/${payload.idUser}/planningbase`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const toRemove = []
                            this.driversActivities.forEach(
                                (activity, index) => {
                                    if (activity.IdUser === payload.idUser) {
                                        toRemove.push(index)
                                    }
                                }
                            )
                            // delete backwards to avaoid messing table up
                            for (let i = toRemove.length - 1; i >= 0; i -= 1)
                                this.driversActivities.splice(toRemove[i], 1)
                            const activities = []
                            if (res.data.PlanningBase.length > 0) {
                                res.data.PlanningBase.forEach((activity) => {
                                    if (
                                        activity.IdUser &&
                                        activity.Active === '1'
                                    ) {
                                        activity.id = +activity.Id
                                        activity.end = activity.End.substring(
                                            0,
                                            5
                                        )
                                        activity.start =
                                            activity.Start.substring(0, 5)
                                        activity.list = `driver-${activity.IdUser}-activities`
                                        activity.title = activity.Name
                                        activity.color = activity.Color
                                        activity.base_activity_id =
                                            activity.IdPlanningActivity
                                        activity.type = activity.Type

                                        activity.List = `driver-${activity.IdUser}-activities`
                                        activity.End = activity.End.substring(
                                            0,
                                            5
                                        )
                                        activity.Start =
                                            activity.Start.substring(0, 5)
                                        activity.basePlanning =
                                            !!activity.IdPlanningBase

                                        activities.push(activity)
                                    }
                                })
                            }
                            this.currentPlanning = activities
                            const driver = res.data.Driver
                            driver.hours = {
                                weekTotal: res.data.PlannedHours,
                            }
                            this.current = driver

                            // const real = {
                            //     driver,
                            //     activities,
                            // }
                            // this.driversCalendar.forEach((cal, index) => {
                            //     if (+cal.driver.IdUser === +payload.id) {
                            //         this.driversCalendar[index] = real
                            //     }
                            // })
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        getDriverTrace(payload) {
            // https://{{APIURL}}/v1/driver/26/route?date=20230420

            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/driver/${payload.id}/route?date=${payload.date}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        // let trace = {
                        //     [payload.id]:{
                        //         geoJson: res.data.GeoJson
                        //     }
                        // }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        SEARCHfetchDriverPlanning(payload) {
            return new Promise((resolve, reject) => {
                this.fetchError = false

                // `/api/v1/driverplanning/${payload}`
                let apiUrl = `/api/v1/planningBase/search?nbPP=100&page=1&order=ASC&sort=WeekDay`
                if (payload.idUser) {
                    apiUrl = `/api/v1/planningBase/search?query={"Query":[{"IdUser":[{"operator":"=","value":"${payload.idUser}"}]}]}&nbPP=100&page=1&order=ASC&sort=WeekDay`
                }
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const activities = res.data.Records
                            activities.forEach((activity) => {
                                activity.id = +activity.Id
                                activity.end = activity.End.substring(0, 5)
                                activity.start = activity.Start.substring(0, 5)
                                activity.list = `driver-${activity.IdUser}-activities`
                                activity.title = activity.Name
                                activity.color = activity.Color
                                activity.base_activity_id =
                                    activity.IdPlanningActivity
                                activity.type = activity.Type

                                activity.List = `driver-${activity.IdUser}-activities`
                                activity.End = activity.End.substring(0, 5)
                                activity.Start = activity.Start.substring(0, 5)
                                activity.baseDriver = true
                            })
                            if (payload.idUser) {
                                this.currentPlanning = activities
                            } else {
                                this.driversActivities = activities
                            }
                        } else {
                            this.currentPlanning = null
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})

export { useDriverStore }
