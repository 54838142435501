<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <filtrable-table
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="orders"
                    :columns="columns"
                    :storeToSort="'orders'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="multiActions"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    @changeSearch="changeSearch()"
                    @checkboxFilterUpdate="checkboxFilter($event)"
                    @dateFilterUpdate="setDateFilter($event)"
                    :hoverableItems="userStore.isB2C === false"
                    :itemActions="itemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="canAddNew()"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @printAllLabels="printLabels($event)"
                    @orderTracking="trackParcels($event)"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @updatedAddress="$emit('updated', $event)"
                    @update="goToUpdateOne($event)"
                    @updateStep="goToUpdateStep($event)"
                    @updateStepDriver="updateStepsDriver($event)"
                    @updateStepStatus="updateStepStatus($event)"
                    @delete="deleteOne($event)"
                    @deleteAll="deleteAll($event)"
                    @packageTracking="$emit('packageTracking', $event)"
                    @updatePlanned="showUpdateRecurrentDate($event)"
                    @deletePlanned="deletePlanned($event)"
                    @updateOrderGroup="openOrderGroupModal($event)"
                >
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
                <modal
                    v-if="showConfirmModal"
                    :headerText="confirmModalHeader"
                    :buttonText="'confirm'"
                    id="deletemodal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="actionConfirmation(false)"
                    @sendFromModal="actionConfirmation(true)"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center font-semibold mt-4">
                                {{ $t(confirmModalText) }}
                            </div>

                            <div
                                v-for="(
                                    toConfirm, iToConfirm
                                ) in elementsToConfirm"
                                :key="iToConfirm"
                                class="flex justify-center mt-2"
                            >
                                <ul>
                                    <li
                                        class="flex text-sm items-center whitespace-nowrap"
                                    >
                                        <fa-icon
                                            icon="circle"
                                            class="fa-2xs mr-2"
                                        />
                                        <span
                                            v-if="toConfirm.Client"
                                            class="mr-1"
                                        >
                                            {{ toConfirm.Client }}
                                        </span>
                                        <span v-if="toConfirm.Ref">
                                            {{
                                                ' - ' +
                                                $t('order_ref') +
                                                ': ' +
                                                toConfirm.Ref
                                            }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="flex text-xs italic items-center error-msg justify-center mt-4"
                            >
                                <fa-icon
                                    :icon="'triangle-exclamation'"
                                    class="mr-2 fa-md"
                                ></fa-icon>
                                {{ $t(confirmModalText2) }}
                            </div>
                        </div></template
                    ></modal
                >
                <modal
                    v-if="showRecurrentDateModal"
                    :headerText="'update_recurrent_date_modal_header'"
                    :buttonText="'save'"
                    id="updateRecurrentDateModal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="showRecurrentDateModal = false"
                    @sendFromModal="updatePlanned()"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center font-semibold mt-4">
                                {{ $t('update_recurrent_date_text') }}
                            </div>
                            <input
                                :id="`recurrentDatePicker`"
                                type="date"
                                v-model="plannedToUpdate.Date"
                                class="border rounded px-3 py-2 w-full"
                            />

                            <!-- <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div> -->
                        </div></template
                    ></modal
                >
                <order-group-modal
                    v-if="showOrderGroupModal"
                    :form="groupForm"
                    :selectedPricing="selectedTourPricing"
                    :tourPricingOptions="tourPricingOptions"
                    @closeModal="openOrderGroupModal()"
                    @updateFromModal="updateGroup($event)"
                >
                </order-group-modal>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import OrderGroupModal from '@/components/elements/OrderGroupModal.vue'
import filterList from '@/mixins/filterList'
import orderGroupsMixin from '@/mixins/orderGroupsMixin'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useOrderStore } from '@/stores/orderStore'
import { useRecurrentOrderStore } from '@/stores/recurrentOrderStore'
import { useTourStore } from '@/stores/tourStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
// import useVuelidate from '@vuelidate/core'
// import { required } from '@vuelidate/validators'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'BecycleOrders',
    mixins: [filterList, orderGroupsMixin],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        Multiselect,
        FetchDataError,
        Modal,
        OrderGroupModal,
    },
    // setup() {
    //     return { v$: useVuelidate() }
    // },
    data() {
        return {
            refreshMS: 0,
            selectedTourPricing: null,
            tourPricingOptions: [],
            showOrderGroupModal: false,
            confirmAction: null,
            plannedToUpdate: null,
            showRecurrentDateModal: false,
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModaltext: 'confirm_modal_text',
            confirmModaltext2: 'confirm_modal_text',
            unpaidInterval: null,
            driverOptions: [],
            dateFilter: [
                {
                    value: new Date().toISOString().substr(0, 10),
                    dbField: 'DateBegin',
                },
                {
                    value: new Date().toISOString().substr(0, 10),
                    dbField: 'DateEnd',
                },
            ],
            orderGroupOptions: [],
            resetCheckedItems: false,
            pickupTodayOnly: true,
            deliveryTodayOnly: true,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'DateBegin',
            perPage: 100,
            page: 1,
            filter: [],
            ordersInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                },
                // { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
        }
    },
    // validations() {
    //     return {
    //         groupForm: {
    //             Name: { required },
    //             IdPricing: { required },
    //         },
    //     }
    // },
    watch: {
        // selectedTourPricing() {
        //     this.groupForm.IdPricing = this.selectedTourPricing.value
        // },
        orders(oldVal, newVal) {
            if (
                this.page === 1 &&
                oldVal !== newVal &&
                (localStorage.getItem('popoverOpen') === null ||
                    localStorage.getItem('popoverOpen') === false)
            ) {
                this.refresh += 1
            }
        },
        // drivers() {
        //     if (this.drivers !== null) {
        //         // clearInterval(driversInterval)
        //         this.drivers.forEach((driver) => {
        //             const name = `${driver.firstname} ${driver.lastname}`
        //             const option = {
        //                 name,
        //                 value: +driver.id,
        //                 dbField: 'OrderStep.IdDriver',
        //             }
        //             this.driverOptions.push(option)
        //         })
        //     }
        // },
    },
    mounted() {
        clearInterval(this.ordersInterval)
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        window.scrollTo(0, 0)
        this.searchingCursor = true

        this.orderGroupOptions = []
        if (this.userStore.isB2C === false) {
            this.refreshOrderGroups(new Date().toISOString().substr(0, 10))
        }

        this.orders = null
        this.count = null
        this.totalPages = null
        this.filter = []
        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)
                const filteredList = this.$cookies.get('filtered')

                if (filteredList) {
                    // this.search.push(this.$cookies.get('query'))
                    this.search = filteredList.query
                    // this.searchQuery = filteredList.query[0]
                    //     ? filteredList.query[0].term
                    //     : ''
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    this.pickupTodayOnly = filteredList.pickupTodayOnly
                    this.deliveryTodayOnly = filteredList.deliveryTodayOnly

                    this.dateFilter = filteredList.dateFilter
                    this.columns.forEach((col, index) => {
                        if (
                            col.type === 'select' ||
                            col.type === 'icon-select'
                        ) {
                            if (!col.multi) {
                                this.setRealSelected(col.dbField, index)
                            } else {
                                this.setRealSelected(col.dbField, index, true)
                            }
                        }
                    })

                    this.setRealSearch(this.search)
                }

                if (this.userStore.isB2C === false) {
                    if (!filteredList && this.$route.name !== 'refunds') {
                        this.filter.push({
                            name: 'delivery_status.in_progress',
                            value: 'inprogress',
                            dbField: 'Status',
                            icon: 'route',
                        })
                        this.filter.push({
                            name: 'delivery_new',
                            value: 'new',
                            dbField: 'Status',
                            icon: 'hourglass-half',
                        })
                        this.filter.push({
                            name: 'imp_orders',
                            value: 'imported',
                            dbField: 'Status',
                            icon: 'file-export',
                        })
                        if (
                            checkAccess(
                                this.userStore,
                                this.ordersActionsAccess.recurrents.list
                            )
                        ) {
                            this.filter.push({
                                name: 'planned',
                                value: 'planned',
                                dbField: 'Status',
                                icon: 'calendar',
                            })
                        }
                        this.setRealSelected('Status', 2, true)
                    }
                    // if (this.userStore.userUniverses.length === 1) {
                    // this.selectedUniverses = structuredClone(
                    //     this.userStore.userUniverses
                    // )
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            const universeFilter = {
                                dbField: 'OrderClient.IdUniverse',
                                value:
                                    this.selectedUniverses.length > 0
                                        ? this.selectedUniverses
                                        : this.universesOptions,
                            }
                            if (
                                !this.filter.find(
                                    (f) =>
                                        f.dbField === 'OrderClient.IdUniverse'
                                )
                            ) {
                                this.filter.push(universeFilter)
                            }
                            const driverUniverses = []

                            universeFilter.value.forEach((universe) => {
                                driverUniverses.push(universe.value)
                            })
                            // }
                            const payload = {
                                query: [],
                                filter: [],
                                perPage: 10000,
                                page: 1,
                                sortField: 'Name',
                                sortDirection: 'ASC',
                            }
                            this.searchContainers(payload)
                            this.availableDriversForDate({
                                date: new Date().toISOString().slice(0, 10),
                                universes: driverUniverses,
                            })
                                .then((res) => {
                                    if (res && res.status === 200 && res.data) {
                                        res.data.forEach((driver) => {
                                            const name = `${driver.FirstName} ${driver.LastName}`
                                            const option = {
                                                name,
                                                value: +driver.Id,
                                                dbField: 'OrderStep.IdDriver',
                                            }
                                            this.driverOptions.push(option)
                                        })
                                    }
                                })
                                .finally(() => {
                                    this.refresh += 1
                                })

                            this.searchOrders({
                                query: this.search,
                                filter: this.filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                                deliveryTodayOnly: this.deliveryTodayOnly,
                                pickupTodayOnly: this.pickupTodayOnly,
                                dateFilter: this.dateFilter,
                            }).then(() => {
                                this.searchingCursor = false
                                clearInterval(this.ordersInterval)

                                this.ordersInterval = setInterval(() => {
                                    this.searchOrders({
                                        query: this.search,
                                        filter: this.filter,
                                        sortDirection: this.sortDirection,
                                        sortField: this.sortField,
                                        perPage: this.perPage,
                                        page: this.page,
                                        deliveryTodayOnly:
                                            this.deliveryTodayOnly,
                                        pickupTodayOnly: this.pickupTodayOnly,
                                        dateFilter: this.dateFilter,
                                    })
                                }, 20000)
                            })
                        }
                    }, 10)
                } else {
                    const payload = {
                        query: [],
                        filter: [],
                        perPage: 10000,
                        page: 1,
                        clientId: this.userStore.current.idClient,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                    }
                    this.sortField = 'CreationDate'
                    this.searchContainersByClient(payload)
                    this.searchDeliveriesByClient({
                        query: this.search,
                        clientId: this.userStore.current.idClient,
                        filter: this.filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                        myCart: this.$route.name === 'my_cart',
                    }).then(() => {
                        this.searchingCursor = false

                        // this.ordersInterval = setInterval(() => {
                        //     if (this.orders !== null) {
                        clearInterval(this.ordersInterval)
                        this.ordersInterval = setInterval(() => {
                            this.searchDeliveriesByClient({
                                query: this.search,
                                clientId: this.userStore.current.idClient,
                                filter: this.filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                                myCart: this.$route.name === 'my_cart',
                            })
                        }, 20000)
                        //     }
                        // })
                    })
                }
            }
        }, 20)
        if (this.$cookies.get('currentDeliveryId'))
            this.$cookies.remove('currentDeliveryId')
    },

    unmounted() {
        clearInterval(this.ordersInterval)
        clearInterval(this.unpaidInterval)
        this.eventBus.all.clear()

        this.orders = null
        this.count = null
        this.totalPages = null
    },

    computed: {
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        // driverOptions() {
        //     const drOptions = []
        //     // const driversInterval = setInterval(() => {
        //     if (this.drivers !== null) {
        //         // clearInterval(driversInterval)
        //         this.drivers.forEach((driver) => {
        //             const name = `${driver.firstname} ${driver.lastname}`
        //             const option = {
        //                 name,
        //                 value: +driver.id,
        //             }
        //             drOptions.push(option)
        //         })
        //     }
        //     // }, 200)
        //     return drOptions
        // },
        statusOptions() {
            const options = [
                {
                    name: 'delivery_status.in_progress',
                    value: 'inprogress',
                    dbField: 'Status',
                    icon: 'route',
                },
                {
                    name: 'delivery_status.finished',
                    value: 'finished',
                    icon: 'flag-checkered',
                    dbField: 'Status',
                },
                {
                    name: 'delivery_new',
                    value: 'new',
                    dbField: 'Status',
                    icon: 'hourglass-half',
                },
                {
                    name: 'imp_orders',
                    value: 'imported',
                    dbField: 'Status',
                    icon: 'file-export',
                },
            ]
            if (
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.recurrents.list
                )
            ) {
                options.push({
                    name: 'planned',
                    value: 'planned',
                    dbField: 'Status',
                    icon: 'calendar',
                })
            }
            return options
        },

        canAccessGroupsCreate() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.groups.create
            )
        },
        canAccessGroupsUpdate() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.groups.update
            )
        },
        methodOptions() {
            const options = [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                    dbField: 'Method',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                    dbField: 'Method',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                    dbField: 'Method',
                },
            ]
            if (this.canAccessGroupsCreate || this.canAccessGroupsUpdate) {
                options.push({
                    name: 'order_group',
                    value: 'orderGrouping',
                    icon: 'arrows-to-circle',
                    style: 'color: blue;',
                    dbField: 'Method',
                })
            }
            return options
        },
        columns() {
            return [
                {
                    name: 'client',
                    showRef: this.searchByName === false,
                    filtrable: true,
                    dbField:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                    isLink: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),
                    routeName: 'update_order',
                    searchIcon: this.searchByName === true ? 'user' : 'hashtag',
                    type: 'search',
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: true,
                },
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'Name',
                    isLink: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),
                    routeName: 'update_order',

                    type: 'search',
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C === false
                        : this.$cookies.get('is_B2C') === 'false',
                },
                // {
                //     name: 'reference',
                //     filtrable: true,
                //     dbField: 'Reference',
                //     type: 'search',
                //     sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C === false
                //         : this.$cookies.get('is_B2C') === 'false',
                // },
                {
                    name: 'status',
                    filtrable: true,
                    type: 'icon-select',
                    dbField: 'Status',

                    withIconNoText: true,
                    sortable: false,
                    multi: true,

                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    // selected: ,
                    options: this.statusOptions,
                },

                {
                    name: 'delivery_mode',
                    filtrable: true,
                    type: 'icon-select',
                    withIconNoText: true,
                    dbField: 'Method',

                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',

                    options: this.methodOptions,
                },
                {
                    name: 'orderGroup',
                    filtrable: true,
                    type: 'select',
                    dbField: 'OrderClientTour_OrderClient.IdOrderClientTour',
                    width: 'w-20',
                    sortable: false,
                    hasAccess: this.ordersActionsAccess.groups.update,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    // selected: ,
                    options: this.orderGroupOptions,
                },

                {
                    name: 'drivers',
                    filtrable: true,
                    sortable: false,
                    drivers: true,
                    type: 'select',
                    dbField: 'OrderStep.IdDriver',
                    options: this.driverOptions,
                    canSearch: true,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),
                    // type: 'search',
                    // dbField: 'OrderSteps.Driver.LastName',
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'pick_up',
                    filtrable: false,
                    notCentered: true,
                    sortable: false,
                    dbField: 'DateBegin',
                    datePicker: true,
                    withButton: true,
                    pickedDate: new Date(),
                    buttonValue: this.pickupTodayOnly,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.updateStatus
                    ),
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'delivery',
                    filtrable: false,
                    notCentered: true,
                    withButton: true,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.updateStatus
                    ),
                    buttonValue: this.deliveryTodayOnly,
                    dbField: 'DateEnd',
                    datePicker: true,
                    pickedDate: new Date(),
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'additional_info',
                    filtrable: false,
                    sortable: false,
                    packages: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'packages',
                    filtrable: false,
                    sortable: false,
                    packages: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C === false
                        : this.$cookies.get('is_B2C') === 'false',
                },
                {
                    name: 'creation_date',
                    sortable: false,
                    dbField: 'CreationDate',
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C === false
                        : this.$cookies.get('is_B2C') === 'false',
                },
            ]
        },

        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.ordersActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            if (this.userStore.isB2C === false) {
                // multiActions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                // })
            }
            return actions
        },
        itemActions(orderStatus) {
            const actions = []

            if (this.userStore.isB2C === true) {
                // actions.push({
                //     sub: true,
                //     action: 'printAllLabels',
                // })

                // actions.push({
                //     action: 'orderTracking',
                //     sub: true,
                // })
                // TODO gérer ici car les commandes à payer dépendent du orderClient pas du client uniquement
                if (
                    this.currentClient &&
                    this.currentClient.client_type === 'occasional' &&
                    this.currentClient.payment_type === 'web' &&
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.webPayment
                    )
                ) {
                    actions.push({
                        name: 'pay',
                        action: 'pay',
                        icon: ['fab', 'cc-stripe'],
                        color: 'stripe-color',
                        hide: false,
                    })
                }
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.update)
                ) {
                    actions.push({
                        name: 'see_details',
                        action: 'update',
                        icon: 'magnifying-glass',
                        hide: false,
                        notAffectedOnly: true,
                    })
                }

                // actions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                //     hide: this.userStore.isB2C,
                // })
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.delete)
                ) {
                    actions.push({
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                        notAffectedOnly: true,
                        hide: false,
                    })
                }
            } else {
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.update)
                ) {
                    actions.push({
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                        hide: false,
                    })
                }
                // actions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                //     hide: false,
                // })
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.delete)
                ) {
                    actions.push({
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                        hide: false,
                    })
                }
                if (
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.recurrents.update
                    )
                ) {
                    actions.push({
                        name: 'edit_date',
                        action: 'updatePlanned',
                        icon: 'pen',
                        hide: false,
                        multiParamItemAction: true,
                    })
                }
                if (
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.recurrents.delete
                    )
                ) {
                    actions.push({
                        name: 'delete',
                        action: 'deletePlanned',
                        icon: 'trash-can',
                        hide: false,
                    })
                }
            }

            return actions
        },
        ...mapWritableState(useOrderStore, {
            orders: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore, useOrderStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        refreshOrderGroups(date = null) {
            this.orderGroupOptions = []
            this.fetchOrderGroups(date).then((orderGroupOptions) => {
                if (orderGroupOptions.status === 200) {
                    orderGroupOptions.data.Records.forEach((group) => {
                        const option = {
                            value: +group.Id,
                            name: group.Name,
                            dbField:
                                'OrderClientTour_OrderClient.IdOrderClientTour',
                        }
                        this.orderGroupOptions.push(option)
                    })
                }
            })
        },
        canAddNew() {
            return (
                checkAccess(this.userStore, this.ordersActionsAccess.create) &&
                this.$route.name !== 'my_cart'
            )
        },
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
            'fetchOne',
        ]),
        ...mapActions(useTourStore, ['moveManyDailySteps']),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
        }),
        ...mapActions(useOrderStore, [
            'searchOrders',
            'searchDeliveriesByClient',
            'deleteOrder',
            'deleteDelivery',
            'deleteManyDeliveries',
            'deleteManyOrders',
            'deleteOrderClient',
            'updateStep',
            'searchUnpaidOrdersByClient',
            'fetchOneDelivery',
            'fetchOrderGroups',
            'fetchOneOrderGroup',
            'updateOrderGroup',
        ]),
        ...mapActions(useRecurrentOrderStore, [
            'updateAndCreateRecurrentDate',
            'fetchOneRecurrentDate',
            'updateRecurrentStep',
            'plannedToDeleted',
            'fetchRecurrentGroups',
        ]),

        async updateGroup(groupForm) {
            this.updateOrderGroup(groupForm)
                .then((res) => {
                    this.$toast.success(this.$t('update_ok'))
                    this.openOrderGroupModal()

                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('error_occured'))
                })
        },
        showUpdateRecurrentDate(planned) {
            this.fetchOneRecurrentDate(planned.idRecurrentDate).then((res) => {
                this.plannedToUpdate = res.data
                this.showRecurrentDateModal = true
            })
        },
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        updatePlanned() {
            this.showRecurrentDateModal = false
            this.plannedToUpdate.userId = this.userStore.current.id

            this.updateAndCreateRecurrentDate(this.plannedToUpdate)
                .then((res) => {
                    this.$toast.success(this.$t('order_update_ok'))
                    this.plannedToUpdate = null
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('error_occured'))
                })
        },

        changeSearch() {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Client.Name') {
                    this.search[index].dbField = 'OrderClient.Reference'
                } else if (filter.dbField === 'OrderClient.Reference') {
                    this.search[index].dbField = 'Client.Name'
                }
            })
            this.setResults()
        },
        goToUpdateOne(id) {
            clearInterval(this.ordersInterval)
            this.$emit('goToUpdateOrder', id)
            if (this.$route.name === 'my_cart') {
                if (this.$cookies.get('currentDeliveryId'))
                    this.$cookies.remove('currentDeliveryId')
                this.fetchOne(this.userStore.current.idClient)
                this.fetchOneDelivery(id)
                this.$router.push({ name: 'update_order', params: { id } })
            }
        },
        goToUpdateStep(event) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateStep', event)
        },
        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },
        printLabels(order) {
            this.axios
                .get(`/api/v1/orderPackage/${order.id}/getlabels`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session'
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    // const url = URL.createObjectURL(res.data)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('print', `order-${order.reference}`)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // URL.revokeObjectURL(url)
                    const iframe = document.createElement('iframe')
                    // Hide the IFrame.
                    iframe.style.visibility = 'hidden'
                    // Define the source.
                    iframe.src = window.URL.createObjectURL(res.data)
                    // Add the IFrame to the web page.
                    document.body.appendChild(iframe)
                    iframe.contentWindow.focus()
                    iframe.contentWindow.print()
                })
        },
        trackParcels(order) {
            const env =
                window.location.hostname === 'localhost' ||
                window.location.host.includes('dev')
                    ? 'dev'
                    : window.location.host.includes('lium')
                    ? 'lium'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            const dot = env === 'beaujolyon' ? 'fr' : 'com'
            window
                .open(
                    `https://${env}.biiim-solutions.${dot}/api/v1/tracking/form/live?reference=${order.reference}`,
                    '_blank'
                )
                .focus()
        },
        updateStepsDriver(infos) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            const stepsToMove = []

            infos.steps.forEach((step, index) => {
                step.IdDriver = infos.newDriver !== null ? infos.newDriver : 0
                step.Status = step.Status === 'new' ? 'affected' : step.Status
                const payload = {
                    id: step.Id,
                    form: step,
                }
                if (!infos.isRecurrent) {
                    stepsToMove.push(step.Id)
                } else {
                    this.updateRecurrentStep(payload)
                        .then((res) => {
                            if (index === infos.steps.length - 1) {
                                this.setResults()
                            }
                        })
                        .catch((err) => {
                            this.searchingCursor = false
                            this.$toast.error(this.$t('error_occured'))
                        })
                }
            })
            if (!infos.isRecurrent) {
                const date = infos.steps[0].FromDateTime
                    ? infos.steps[0].FromDateTime
                    : infos.steps[0].BeforeDateTime
                const payload = {
                    IdOrderStepEnd: stepsToMove,
                    IdTour: 0,
                    ModificationUserId: this.userStore.current.id,
                    IdDriver: infos.newDriver !== null ? infos.newDriver : 0,
                    Date: date.slice(0, 10),
                    // IdTour: this.current.Id,
                }
                this.moveManyDailySteps(payload)
                    .then((res) => {
                        // if (index === infos.steps.length - 1) {
                        this.setResults()
                        // }
                    })
                    .catch((err) => {
                        this.searchingCursor = false
                        this.$toast.error(this.$t('error_occured'))
                    })
            }
        },
        updateStepStatus(step) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)

            const clone = structuredClone(step)
            if (step.newStatus !== 'executed') {
                clone.step.ExecutedDateTime = null
            }

            clone.step.Status = step.newStatus
            clone.step.ModificationUserId = this.userStore.current.id
            const payload = {
                id: clone.step.Id,
                form: clone.step,
            }
            this.updateStep(payload)
                .then((res) => {
                    step.step.Status = step.newStatus
                    step.step.ModificationUserId = this.userStore.current.id
                    const orderInUpdate = this.orders.find(
                        (ord) => ord.id === +step.step.IdOrder
                    )
                    const updatedStepIndex = orderInUpdate.steps.findIndex(
                        (stp) => +stp.Id === +step.step.Id
                    )
                    if (step.newStatus !== 'executed') {
                        step.step.ExecutedDateTime = null
                        if (
                            step.updateStepAfter &&
                            step.updateStepAfter === true
                        ) {
                            orderInUpdate.steps.forEach(
                                (stpToUpdate, index) => {
                                    if (updatedStepIndex < index) {
                                        stpToUpdate.Status = stpToUpdate.Driver
                                            ? 'affected'
                                            : 'new'
                                        stpToUpdate.ExecutedDateTime = null
                                        stpToUpdate.ModificationUserId =
                                            this.userStore.current.id
                                        const payloadStepAfter = {
                                            id: stpToUpdate.Id,
                                            form: stpToUpdate,
                                        }
                                        this.updateStep(payloadStepAfter)
                                    }
                                }
                            )
                        }
                        // TODO BUG 550 Status of undefined qudn on utilise le switch sur le scond step
                        // fix déployer mais à revoir
                        // else if (
                        //     updatedStepIndex + 1 <
                        //     orderInUpdate.steps.length
                        // ) {
                        //     console.log('coucou')
                        //     const stpToUpdate =
                        //         orderInUpdate.steps[updatedStepIndex + 1]

                        //     stpToUpdate.Status = 'inProgress'
                        //     stpToUpdate.ExecutedDateTime = null
                        //     stpToUpdate.ModificationUserId =
                        //         this.userStore.current.id
                        //     const payloadStepAfter = {
                        //         id: stpToUpdate.Id,
                        //         form: stpToUpdate,
                        //     }
                        //     this.updateStep(payloadStepAfter).then((res) => {
                        //         this.fetchOne(this.$route.params.id)
                        //     })
                        // }
                    }

                    this.setResults()
                })
                .catch((err) => {
                    this.searchingCursor = false
                    if (err.data && err.data.status === 400) {
                        this.$toast.error(this.$t('previous_step_not_done'))
                    } else {
                        // this.$toast.error(this.$t('error_occured'))
                    }
                })
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmModal = false
        },
        deletePlanned(planned) {
            this.searchingCursor = true
            this.confirmAction = null
            this.elementsToConfirm = []
            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            const orderInfo = {
                Client: planned.client,
                Ref: this.$t('planned'),
            }
            this.elementsToConfirm.push(orderInfo)
            const form = {
                userId: this.userStore.current.id,
                Id: planned.idRecurrentDate,
            }
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.plannedToDeleted(form)
                            .then((res) => {
                                this.$toast.success(this.$t('order_delete_ok'))
                                this.setResults()
                            })
                            .catch((err) => {
                                this.$toast.error(this.$t('error_occured'))
                            })
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            this.confirmAction = null
            this.elementsToConfirm = []
            let response = null
            let toastSuccessMessage = 'order_delete_ok'
            let toastErrorMessage = 'order_delete_ko'
            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            const fullOrder = this.orders.find((order) => order.id === id)

            if (this.userStore.isB2C === true) {
                const orderInfo = {
                    Client: fullOrder.name,
                }
                this.elementsToConfirm.push(orderInfo)
            } else {
                const orderInfo = {
                    Client: fullOrder.client,
                    Ref: fullOrder.reference,
                }
                this.elementsToConfirm.push(orderInfo)
            }
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null

                        if (this.userStore.isB2C === true) {
                            toastSuccessMessage = 'delivery_delete_ok'
                            toastErrorMessage = 'delivery_delete_ko'
                            response = await this.deleteDelivery(id)
                        } else {
                            id = fullOrder.idOrderClient
                            response = await this.deleteOrderClient(id)
                        }

                        if (response.status === 200) {
                            this.setResults()
                            this.searchingCursor = false

                            this.$toast.success(this.$t(toastSuccessMessage))
                        } else {
                            this.searchingCursor = false

                            this.$toast.error(this.$t(toastErrorMessage))
                        }
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        async deleteAll(elToDelete) {
            this.searchingCursor = true

            const elements = []
            let response = null

            this.confirmAction = null
            this.elementsToConfirm = []

            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            elToDelete.forEach((id) => {
                const fullOrder = this.orders.find((order) => order.id === id)
                if (this.userStore.isB2C === true) {
                    const orderInfo = {
                        Client: fullOrder.name,
                    }
                    this.elementsToConfirm.push(orderInfo)
                    elements.push({ Id: id })
                } else {
                    const orderInfo = {
                        Client: fullOrder.client,
                        Ref: fullOrder.reference,
                    }
                    this.elementsToConfirm.push(orderInfo)
                    elements.push({ Id: fullOrder.idOrderClient })
                }
            })
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []

                    if (this.confirmAction === true) {
                        this.confirmAction = null

                        if (this.userStore.isB2C === true) {
                            response = await this.deleteManyDeliveries(elements)
                        } else {
                            this.searchingCursor = false

                            response = await this.deleteManyOrders(elements)
                        }
                        if (response.status === 200) {
                            this.setResults()
                            this.resetCheckedItems = true
                            this.$toast.success(this.$t('delete_many_ok'))
                            this.searchingCursor = false
                        } else {
                            this.$toast.error(this.$t('delete_many_ko'))
                            this.searchingCursor = false
                        }
                        this.resetCheckedItems = false
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        checkboxFilter(dbField) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            const filtToRemoveIndex = this.dateFilter.findIndex(
                (filt) => filt.dbField === dbField
            )
            if (dbField === 'DateBegin') {
                this.pickupTodayOnly = !this.pickupTodayOnly
                if (this.pickupTodayOnly === false) {
                    if (filtToRemoveIndex !== -1) {
                        this.dateFilter.splice(filtToRemoveIndex, 1)
                    }
                    this.refreshOrderGroups(null)
                } else if (
                    this.pickupTodayOnly === true &&
                    filtToRemoveIndex === -1
                ) {
                    this.dateFilter.push({
                        dbField,
                        value: new Date().toISOString().slice(0, 10),
                    })
                    this.refreshOrderGroups(
                        new Date().toISOString().slice(0, 10)
                    )
                }
            }
            if (dbField === 'DateEnd') {
                this.deliveryTodayOnly = !this.deliveryTodayOnly
                if (this.deliveryTodayOnly === false) {
                    if (filtToRemoveIndex !== -1) {
                        this.dateFilter.splice(filtToRemoveIndex, 1)
                    }
                } else if (
                    this.pickupTodayOnly === true &&
                    filtToRemoveIndex === -1
                ) {
                    this.dateFilter.push({
                        dbField,
                        value: new Date().toISOString().slice(0, 10),
                    })
                }
            }
            this.setResults()
        },
        setDateFilter(dFilter) {
            this.searchingCursor = true
            this.dateFilter = []
            if (dFilter.dbField === 'DateBegin') {
                this.pickupTodayOnly = false
            }
            if (dFilter.dbField === 'DateEnd') {
                this.deliveryTodayOnly = false
            }
            const filterExistsIndex = this.dateFilter.findIndex(
                (df) => df.dbField === dFilter.dbField
            )
            if (filterExistsIndex !== -1) {
                this.dateFilter[filterExistsIndex] = dFilter
            } else {
                this.dateFilter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }
            this.refreshOrderGroups(dFilter.value)

            this.setResults()
        },
        setPage(isNext) {
            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            // const query = search.query

            this.$cookies.set('filtered', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''
            if (this.userStore.isB2C === true) {
                search.myCart = this.$route.name === 'my_cart'

                this.searchDeliveriesByClient(search)
                this.searchingCursor = false
                clearInterval(this.ordersInterval)

                this.ordersInterval = setInterval(() => {
                    this.searchDeliveriesByClient(search)
                }, 20000)
            } else {
                this.searchOrders(search)
                this.searchingCursor = false
                clearInterval(this.ordersInterval)

                this.ordersInterval = setInterval(() => {
                    this.searchOrders(search)
                }, 20000)
            }

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('filtered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.ordersInterval)

            const search = {
                query: this.search,
                clientId:
                    this.userStore.isB2C === true
                        ? this.userStore.current.idClient
                        : '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            if (this.userStore.isB2C === false) {
                search.deliveryTodayOnly = this.deliveryTodayOnly
                search.pickupTodayOnly = this.pickupTodayOnly
                search.dateFilter = this.dateFilter
            }
            const uniFilter = this.filter.find(
                (filt) => filt.dbField === 'OrderClient.IdUniverse'
            )
            const universes = []
            uniFilter.value.forEach((uni) => {
                universes.push(uni.value)
            })
            if (this.dateFilter.length === 0) {
                const drPayload = {
                    query: [],
                    filter: [
                        {
                            dbField: 'Active',
                            value: 1,
                        },
                        {
                            dbField: 'UserUniverse.IdUniverse',
                            value: universes,
                        },
                    ],
                    perPage: 10000,
                    page: 1,
                    sortField: 'LastName',
                    sortDirection: 'ASC',
                }
                this.driverOptions = []
                this.searchDrivers(drPayload)
                    .then(() => {
                        this.drivers.forEach((driver) => {
                            const name = `${driver.firstname} ${driver.lastname}`
                            const option = {
                                name,
                                value: +driver.id,
                                dbField: 'OrderStep.IdDriver',
                            }
                            this.driverOptions.push(option)
                        })
                    })
                    .finally(() => {
                        this.refresh += 1
                    })
            } else {
                this.driverOptions = []

                this.availableDriversForDate({
                    date: this.dateFilter[0].value,
                    universes,
                })
                    .then((res) => {
                        if (res && res.status === 200 && res.data) {
                            res.data.forEach((driver) => {
                                const name = `${driver.FirstName} ${driver.LastName}`
                                const option = {
                                    name,
                                    value: +driver.Id,
                                    dbField: 'OrderStep.IdDriver',
                                }
                                this.driverOptions.push(option)
                            })
                        }
                    })
                    .finally(() => {
                        this.refresh += 1
                    })
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
