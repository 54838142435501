<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'package_format'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="containersList"
                :items="containers"
                :columns="columns"
                :storeToSort="'containers'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateContainer()"
                @clear="clearFilter($event)"
                @update="toUpdateContainer($event)"
                @deactivate="deactivateOne($event)"
                @activate="activateOne($event)"
                @deactivateAll="deactivateAll($event)"
                @setAsDefault="setNewDefault($event)"
            >
                <!-- @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)" -->
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            :headerText="modalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <span v-if="userStore.isB2C === false">
                    <div
                        v-if="!$route.params.id"
                        class="my-3 items-center flex flex-row px-3"
                    >
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('client') + ' *' }}</label
                        >

                        <Multiselect
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="formData.IdClient"
                            :options="clientOptions"
                            :loading="clientOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('select_client_info')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>
                    </div>
                    <div v-else class="my-3 items-center flex flex-row px-3">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('client') + ' *' }}</label
                        >{{ setClient() }}
                    </div>
                </span>
                <div class="mb-3 items-center flex flex-row mt-2 px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('package_name') + ' *' }}</label
                    >
                    <input
                        v-model="formData.Name"
                        :disabled="editContainer === true"
                        :class="
                            editContainer === true ? 'cursor-not-allowed' : ''
                        "
                        autocomplete="name"
                        @blur="v$.formData.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.formData.Name.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="mb-3 items-center flex flex-row px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('description') }}</label
                    >
                    <textarea
                        v-model="formData.Description"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>

                <div class="grid grid-cols-4 gap-2 mt-4 px-3">
                    <div class="col-span-1">
                        <label
                            class="font-semibold flex items-center justify-center text-gray-600 pb-1 mr-2"
                            >{{ $t('width') + ' *' }}</label
                        >
                        <input
                            type="number"
                            step="0.01"
                            min="0.01"
                            :disabled="editContainer === true"
                            :class="
                                editContainer === true
                                    ? 'cursor-not-allowed'
                                    : ''
                            "
                            v-model="formData.Width"
                            @blur="v$.formData.Width.$touch"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.formData.Width.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label
                            class="font-semibold flex items-center justify-center text-gray-600 pb-1 mr-2"
                            >{{ $t('length') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="0.01"
                            v-model="formData.Length"
                            step="0.01"
                            :disabled="editContainer === true"
                            :class="
                                editContainer === true
                                    ? 'cursor-not-allowed'
                                    : ''
                            "
                            @blur="v$.formData.Length.$touch"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.formData.Length.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label
                            class="font-semibold flex items-center justify-center text-gray-600 pb-1 mr-2"
                            >{{ $t('height') }}</label
                        >
                        <input
                            type="number"
                            step="0.01"
                            min="0"
                            :disabled="editContainer === true"
                            :class="
                                editContainer === true
                                    ? 'cursor-not-allowed'
                                    : ''
                            "
                            v-model="formData.Height"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                    </div>
                    <div class="col-span-1">
                        <label
                            class="font-semibold flex items-center justify-center text-gray-600 pb-1 mr-2"
                            >{{ $t('unit_weight') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="0.01"
                            step="0.01"
                            @blur="v$.formData.Weight.$touch"
                            :disabled="editContainer === true"
                            :class="
                                editContainer === true
                                    ? 'cursor-not-allowed'
                                    : ''
                            "
                            v-model="formData.Weight"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.formData.Weight.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        userStore.isB2C === false &&
                        +userStore.current.env.defaultContainer === +formData.Id
                    "
                    class="mt-4 px-3"
                >
                    <div
                        class="theme-color flex text-sm italic items-center justify-center font-semibold"
                    >
                        {{ $t('default_container_info') }}
                    </div>
                    <div
                        class="flex text-sm italic items-center justify-center"
                    >
                        {{ $t('operator_update_default') }}
                    </div>
                </div>
                <div
                    v-if="containersActionsAccess.updateDefault"
                    class="mt-4 px-3"
                >
                    <span
                        v-if="
                            userStore.isB2C === true &&
                            +currentClient.default_container === +formData.Id
                        "
                        class="flex items-center justify-center"
                    >
                        <div
                            class="theme-color flex text-sm italic items-center justify-center font-semibold"
                        >
                            {{ $t('default_container_info') }}
                        </div>
                    </span>
                    <span
                        v-else-if="
                            (formData.IdClient !== '' &&
                                formData.IdClient !== 0 &&
                                formData.IdClient !== '0' &&
                                formData.IdClient !== null) ||
                            (userStore.isB2C === true &&
                                $route.name === 'new_container')
                        "
                        class="flex items-center justify-center"
                    >
                        <input
                            id="set-as-default-checkbox"
                            type="checkbox"
                            v-model="isDefault"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                            :class="'cursor-pointer'"
                        />
                        <label
                            for="set-as-default-checkbox"
                            class="ml-2 text-base font-medium"
                            >{{
                                $t(
                                    userStore.isB2C === true
                                        ? 'set_as_default'
                                        : 'set_as_default_for_client'
                                )
                            }}</label
                        >
                    </span>
                </div>
                <span
                    class="flex mt-3 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { minValue, required } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ContainersList',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            isDefault: false,
            editContainer: false,
            modalHeaderText: 'new_container',
            creating: false,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            show: false,
            clientOptions: [
                {
                    name: this.$t('all_clients'),
                    value: 0,
                },
            ],
            containersInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],

            formData: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: '',
                Width: '',
                Length: '',
                Weight: '',
                Description: '',
                Active: 1,
                IdClient: 0,
            },
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
                Width: { required, minValueValue: minValue(0.01) },
                Length: { required, minValueValue: minValue(0.01) },
                Weight: { required, minValueValue: minValue(0.01) },
            },
        }
    },
    watch: {
        containers() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        this.isDefault = false
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
            this.setRealSelected('Active', 0)
        }

        const isB2CInterval = setInterval(() => {
            if (
                this.userStore.isB2C !== undefined &&
                this.userStore.isB2C !== null
            ) {
                this.clientStore.$patch({
                    containers: null,
                    containersCount: null,
                })
                clearInterval(isB2CInterval)
                if (this.userStore.isB2C === true) {
                    this.clientStore.searchContainersByClient({
                        query: this.search,
                        clientId: this.userStore.current.idClient,
                        filter: this.filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    })
                    this.containersInterval = setInterval(() => {
                        this.clientStore.searchContainersByClient({
                            query: this.search,
                            clientId: this.userStore.current.idClient,

                            filter: this.filter,
                            sortDirection: this.sortDirection,
                            sortField: this.sortField,
                            perPage: this.perPage,
                            page: this.page,
                        })
                    }, 20000)
                } else {
                    this.clientStore
                        .search({
                            query: [],
                            filter: [],
                            sortDirection: this.sortDirection,
                            sortField: this.sortField,
                            perPage: 100000,
                            page: this.page,
                        })
                        .then(() => {
                            this.clients.forEach((client) => {
                                this.clientOptions.push({
                                    name: client.company_name,
                                    value: +client.id,
                                })
                            })
                            this.clientStore
                                .searchContainers({
                                    query: this.search,
                                    filter: this.filter,
                                    sortDirection: this.sortDirection,
                                    sortField: this.sortField,
                                    perPage: this.perPage,
                                    page: this.page,
                                    addClientInfo: true,
                                })
                                .then(() => {
                                    this.containersInterval = setInterval(
                                        () => {
                                            this.clientStore.searchContainers({
                                                query: this.search,
                                                filter: this.filter,
                                                sortDirection:
                                                    this.sortDirection,
                                                sortField: this.sortField,
                                                perPage: this.perPage,
                                                page: this.page,
                                                addClientInfo: true,
                                            })
                                        },
                                        5000
                                    )
                                })
                        })
                }
            }
        }, 50)
    },

    unmounted() {
        clearInterval(this.containersInterval)
    },
    computed: {
        containersActionsAccess() {
            return actionsAccess.orders.containers
        },
        ...mapStores(useUserStore),
        ...mapState(useClientStore, {
            containers: 'containers',
            count: 'containersCount',
            fetchError: 'fetchError',
            totalPages: 'containersTotalPages',
            loading: 'loading',
            currentContainer: 'currentContainer',
            clients: 'all',
            currentClient: 'current',
        }),
        ...mapStores(useClientStore),
        columns() {
            return [
                {
                    name: 'active',
                    filtrable: true,
                    dbField: 'Active',
                    type: 'select',
                    sortable: false,
                    withIconNoText: true,
                    selected: {
                        name: this.$t('active'),
                        value: 1,
                        dbField: 'Active',
                    },
                    options: [
                        {
                            name: 'active',
                            value: 1,
                            dbField: 'Active',
                        },
                        {
                            name: 'deactivated',
                            value: 0,
                            dbField: 'Active',
                        },
                    ],
                },
                // gérer le islink pour b2C
                {
                    name: 'name',
                    filtrable: true,

                    isLink:
                        this.userStore.isB2C === false &&
                        checkAccess(
                            this.userStore,
                            this.containersActionsAccess.update
                        ),
                    // routeName: 'update_container',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'owned_by',
                    filtrable: true,
                    dbField: 'IdClient',
                    type: 'select',
                    sortable: false,
                    subInfo: this.userStore.isB2C === false,
                    withIconNoText: false,
                    selected: {
                        name: this.$t('all'),
                        value: 'all',
                        dbField: 'IdClient',
                    },
                    options: [
                        {
                            name: 'all',
                            value: 'all',
                            dbField: 'IdClient',
                        },
                        {
                            name: 'mine_only',
                            hide: this.userStore.isB2C === false,
                            value: this.userStore.current
                                ? this.userStore.current.idClient
                                : '',
                            dbField: 'IdClient',
                        },
                        {
                            name: 'global_only',
                            value: '0',
                            dbField: 'IdClient', // 0
                        },
                    ],
                },
                {
                    name: 'size',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'description',
                    filtrable: false,
                    sortable: false,
                },

                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
                // {
                //     name: 'client',
                //     hide: this.$cookies.get('is_B2C') === true,
                // },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.containersActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                    containers: true,
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.containersActionsAccess.updateDefault
                )
            ) {
                if (this.userStore.isB2C === true) {
                    actions.push({
                        name: 'set_as_default',
                        action: 'setAsDefault',
                        icon: 'heart',
                        containers: true,
                        multiParamItemAction: true,
                    })
                } else {
                    actions.push({
                        name: 'set_as_default_for_client',
                        action: 'setAsDefault',
                        icon: 'heart',
                        containers: true,
                        multiParamItemAction: true,
                    })
                }
                // && que c'est pas le default
            }
            if (
                checkAccess(
                    this.userStore,
                    this.containersActionsAccess.activate
                )
            ) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                    containers: true,
                })
            }

            return actions
        },
        multiActions() {
            if (
                checkAccess(
                    this.userStore,
                    this.containersActionsAccess.activate
                )
            ) {
                return [
                    {
                        name: 'deactivate_selection',
                        action: 'deactivateAll',
                        icon: 'times',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.containersInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.containersActionsAccess.create
            )
        },
        setNewDefault(container) {
            this.searchingCursor = true
            const payload = {
                IdClient: this.currentClient
                    ? this.currentClient.id
                    : container.client,
                form: {
                    IdDefaultContainer: container.id,
                    ModificationUserId: this.userStore.current.id,
                },
                reloadClient: this.userStore.isB2C === true,
            }
            this.clientStore
                .setNewDefaultContainer(payload)
                .then((response) => {
                    if (response.status === 200) {
                        this.searchingCursor = false
                        if (this.userStore.isB2C === true) {
                            this.$toast.success(this.$t('default_container_ok'))
                        } else {
                            this.$toast.success(
                                `${this.$t(
                                    'default_container_ok_for_client'
                                )} ${response.data.Name}`
                            )
                        }
                        this.setResults()
                    } else {
                        this.searchingCursor = false
                        if (this.userStore.isB2C === true) {
                            this.$toast.success(this.$t('default_container_ko'))
                        } else {
                            this.$toast.success(
                                `${this.$t(
                                    'default_container_ko_for_client'
                                )} ${response.data.Name}`
                            )
                        }
                    }
                })
        },
        async deactivateOne(container) {
            this.searchingCursor = true

            const response = await this.clientStore.deactivateContainer(
                container.id
            )
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('container_deactivate_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('container_deactivate_ko'))
            }
        },
        activateOne(container) {
            this.searchingCursor = true
            this.clientStore.$patch({ currentContainer: null })
            this.clientStore.fetchOneContainer(container.id).then(() => {
                const containerInterval = setInterval(() => {
                    if (this.currentContainer !== null) {
                        clearInterval(containerInterval)

                        const form = {
                            id: container.id,
                            form: {
                                Name: this.currentContainer.Name,
                                Id: this.currentContainer.Id,
                                CreationUserId:
                                    this.currentContainer.CreationUserId,
                                CreationDate:
                                    this.currentContainer.CreationDate,

                                ModificationUserId: this.userStore.current.id,
                                Height: this.currentContainer.Height,
                                Width: this.currentContainer.Width,
                                Weight: this.currentContainer.Weight,
                                Length: this.currentContainer.Length,
                                Description: this.currentContainer.Description,
                                Active: 1,
                                IdClient: this.currentContainer.IdClient,
                            },
                        }
                        this.clientStore
                            .updateContainer(form)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.searchingCursor = false

                                    this.$toast.success(
                                        this.$t('container_activate_ok')
                                    )
                                } else {
                                    this.searchingCursor = false

                                    this.$toast.error(
                                        this.$t('container_activate_ko')
                                    )
                                }
                            })
                            .catch((err) => {
                                this.searchingCursor = false

                                this.$toast.error(
                                    this.$t('container_activate_ko')
                                )
                            })
                    }
                }, 20)
            })
        },
        deactivateAll(elementsToDeactivate) {
            this.searchingCursor = true

            elementsToDeactivate.forEach((el, index) => {
                this.clientStore.deactivateContainer(el)
                if (index === elementsToDeactivate.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setClient() {
            let client = { company_name: this.$t('all_clients') }
            if (+this.formData.IdClient !== 0) {
                client = this.clients.find(
                    (cl) => cl.id === +this.formData.IdClient
                )
            }

            return client.company_name
        },
        toCreateContainer() {
            clearInterval(this.containersInterval)
            // this.$router.push({ name: 'new_container' })
            this.isDefault = false
            this.clientStore.$patch({ currentContainer: null })
            this.modalHeaderText = 'new_container'
            this.show = true
        },
        toUpdateContainer(container) {
            this.isDefault = false
            clearInterval(this.containersInterval)

            this.modalHeaderText = 'update_container'
            let id = container
            if (container.id) {
                id = container.id
            }

            this.clientStore.$patch({ currentContainer: null })
            this.clientStore.fetchOneContainer(id).then(() => {
                const containerInterval = setInterval(() => {
                    if (this.currentContainer !== null) {
                        clearInterval(containerInterval)
                        // if (+this.currentContainer.IdClient === 0) {
                        //     this.$toast.warning(
                        //         this.$t('container_not_editable')
                        //     )
                        // } else {
                        this.editContainer = true

                        this.$router.push({
                            name: 'update_container',
                            params: { id: this.currentContainer.Id },
                        })
                        this.formData = {
                            Name: this.currentContainer.Name,
                            Id: this.currentContainer.Id,
                            CreationUserId: this.currentContainer,
                            Height: this.currentContainer.Height,
                            Width: this.currentContainer.Width,
                            Weight: this.currentContainer.Weight,
                            Length: this.currentContainer.Length,
                            Description: this.currentContainer.Description,
                            Active: this.currentContainer.Active,
                            IdClient: this.currentContainer.IdClient,
                        }
                        if (this.userStore.isB2C === true) {
                            if (
                                this.currentClient &&
                                this.currentClient.default_container ===
                                    +this.currentContainer.Id
                            ) {
                                this.isDefault = true
                            }
                        } else if (
                            this.userStore.isB2C === false &&
                            this.userStore.current.env.defaultContainer ===
                                +this.currentContainer.Id
                        ) {
                            this.isDefault = true
                        }
                        this.show = true
                    }
                    // }
                })
            })
        },
        closeModal() {
            this.isDefault = false

            this.creating = false
            this.v$.$reset()
            this.show = false
            this.editContainer = false
            this.formData = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: '',
                Width: '',
                Weight: '',
                Length: '',
                Description: '',
                Active: 1,
                IdClient: 0,
            }
            this.setResults()
        },
        async submitModal() {
            this.searchingCursor = true

            this.creating = true
            const isValid = await this.v$.$validate()
            if (isValid === true) {
                this.formData.IsClientDefault = this.isDefault
                this.formData.CreationUserId = this.userStore.current.id
                if (this.editContainer === true) {
                    this.formData.ModificationUserId = this.userStore.current.id

                    const response = await this.clientStore.updateContainer({
                        id: this.formData.Id,
                        form: this.formData,
                    })
                    if (response.status === 200) {
                        this.searchingCursor = false

                        this.$toast.success(this.$t('container_update_ok'))
                        this.$router.push({ name: 'containers' })
                        this.setResults()
                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('container_update_ko'))
                    }
                } else {
                    if (this.userStore.isB2C === true) {
                        this.formData.IdClient = this.userStore.current.idClient
                    }
                    const response = await this.clientStore.createContainer(
                        this.formData
                    )
                    if (response.status === 200) {
                        this.searchingCursor = false

                        this.setResults()

                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('container_create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.searchingCursor = false

                this.$toast.error(this.$t('form_errors'))
                return false
            }
        },
        // deleteAll(elToDelete) {
        //     elToDelete.forEach((el) => {
        //         this.clientStore.deleteContainer(el)
        //     })
        // },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.containersInterval)
            this.$cookies.remove('filtered')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.containersInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            if (this.userStore.isB2C === true) {
                this.clientStore.searchContainersByClient(search).then(() => {
                    this.searchingCursor = false
                    this.refresh += 1
                })
                this.containersInterval = setInterval(() => {
                    this.clientStore.searchContainersByClient(search)
                }, 20000)
            } else {
                search.addClientInfo = true
                this.clientStore.searchContainers(search).then(() => {
                    this.searchingCursor = false
                    this.refresh += 1
                })
                this.containersInterval = setInterval(() => {
                    this.clientStore.searchContainers(search)
                }, 20000)
            }
            this.$cookies.set('filtered', search)

            this.refresh += 1
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.containersInterval)
            this.$cookies.remove('filtered')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                clientId: this.userStore.current.idClient,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
<style lang="scss" scoped>
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
</style>
